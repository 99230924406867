<template>
  <div class="card">
    <div class="border-0 card-header">
      <h3 class="mb-0">Templates</h3>
    </div>
    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="items">
      <el-table-column label="Template"
                       min-width="310px"
                       prop="name"
                       sortable>
        <template v-slot="{row}">
          <div class="media align-items-center">
            <div class="media-body">
              <a :href="'#/settings/template/' + row.id">
                <span class="font-weight-600 name mb-0 text-sm">{{ row.title }}</span>
              </a>
            </div>
          </div>
        </template>
      </el-table-column>
<!--      <el-table-column label="Categories"
                       min-width="310px"
                       prop="name"
                       sortable>
        <template v-slot="{row}">
          <div class="media align-items-center">
            <div class="media-body">
              <span class="font-weight-600 name mb-0 text-sm">{{ row.taxonomies.join(', ') }}</span>
            </div>
          </div>
        </template>
      </el-table-column>-->
      <el-table-column min-width="180px">
        <template v-slot="{row}">
          <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
              <a class="dropdown-item" :href="'#/settings/template/'+row.id">Edit Template</a>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <!--<base-pagination v-model="currentPage" :total="50"></base-pagination>-->
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'

export default {
  name: 'messages-table',
  props: {
    items: [Array, Object],
    default: []
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      currentPage: 1
    };
  }
}
</script>
