<template>
  <div>
    <form v-on:submit.prevent="formSubmit" :action="formAction" id="productForm" enctype="multipart/form-data">
      <base-header class="pb-6 bg-default">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.params.id === 'new' ? 'Add' : 'Edit' }}
              {{ $route.name }}</h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <route-breadcrumb/>
            </nav>
          </div>
          <div class="col-lg-6 col-5 text-right">
            <base-button size="sm" type="neutral" v-on:click="$router.push('/' + routeBase + '/product/new')">New
            </base-button>
            <base-button size="sm" type="neutral" v-on:click="$router.go(-1)">Back</base-button>
            <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
          </div>
        </div>
        <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
          <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{ errorMessage }}</div>
          <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
        </div>
      </base-header>
      <div class="container-fluid mt--6 loading-container" v-if="!showForm">
        <div class="row">
          <div class="col-12">
            Loading...
          </div>
        </div>
      </div>
      <div class="container-fluid mt--6" v-else>
        <div class="row">
          <div class="col-lg-6">
            <div class="card-wrapper">
              <!-- Form controls -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header">
                  <div class="float-right badge-warning py-1 px-3" v-if="isArchived">Archived</div>
                  <h3 class="mb-0">Details</h3>
                </div>
                <!-- Card body -->
                <div class="card-body">
                  <base-input label="Status">
                    <select class="form-control" v-model="status">
                      <option value="1">Published</option>
                      <option value="2">Draft</option>
                    </select>
                  </base-input>
                  <base-input label="Title" placeholder="Product title" v-model="title"/>
                  <base-input label="Alias" placeholder="Alias (e.g. product-title)" v-model="alias"
                              v-on:keyup="handleAliasKeyup" v-on:blur="handleAliasBlur"
                              :class="['alias', {'in-use': !aliasAvailable}]"/>
                  <div class="row" v-if="this.routeBase !== 'retailers'">
                    <base-input prepend-icon="fas fa-dollar-sign" placeholder="0.00" label="Price" class="col"
                                v-model="item_price"/>
                    <base-input prepend-icon="fas fa-dollar-sign" placeholder="0.00"
                                :label="config.config_domain == 'shopwillowbrookcom' ? 'GST' : 'HST'"
                                class="col"
                                v-model="item_tax"/>
                    <base-input prepend-icon="fas fa-dollar-sign" placeholder="0.00" label="PST" class="col"
                                v-model="item_tax2" v-if="config.config_domain == 'shopwillowbrookcom'"/>
                  </div>
                  <Tags label="Vendor(s)" v-model="vendors" :options="allVendors" placeholder="Add a vendor"/>
                  <Tags label="Categories" v-model="taxonomies"
                        :options="routeBase === 'retailers' ? allVendorTaxonomies : allProductTaxonomies"
                        placeholder="Add a category"/>
                  <Tags label="Lists" v-model="assigned_to" :options="allAssignees"
                        placeholder="Add product to a list"/>
                </div>
              </div>
            </div>
            <div class="card-wrapper" v-if="this.routeBase !== 'retailers'">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Inventory</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <base-input label="Available" v-model="item_ots"/>
                    </div>
                    <div class="col-6">
                      <base-input label="Decrement by" v-model="item_decrement"/>
                    </div>
                  </div>
                  <div class="d-flex">
                    <base-switch v-model="item_ignore_ots" type="default"/>
                    <label class="ml-3">Continue selling if availability reaches 0</label>
                  </div>
                  <div class="d-flex mt-3">
                    <base-input label="Sequence" v-model="sequence"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Primary Image</h3>
                </div>
                <div class="card-body">
                  <img class="product-image mb-4" v-if="$store.state.formData.productForm.image"
                       :src="$store.state.formData.productForm.image"/>
                  <file-input name="image"></file-input>
                </div>
              </div>
            </div>
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Additional Images</h3>
                </div>
                <div class="card-body">
                  <AltImagesGallery :images="alt_image" />
                </div>
              </div>
            </div>
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Description</h3>
                </div>
                <div class="card-body">
                  <html-editor v-model="content"></html-editor>
                </div>
              </div>
            </div>
            <div class="card-wrapper" v-if="this.routeBase !== 'retailers'">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Special Handling</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <base-input label="Lead Time Required (Hours)" placeholder="Leave blank for 24 hours"
                                  v-model="prep_time"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="this.routeBase !== 'retailers'">
          <div class="col-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0 float-left">Variants</h3>
                  <div class="float-right d-flex align-items-center">
                    <p class="d-inline-block my-1 mr-2">This product has variants:</p>
                    <base-switch v-model="showVariants"></base-switch>
                  </div>
                </div>
                <div class="card-body">
                  <ProductVariants v-if="showVariants" v-model="subsidiaries"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <base-button size="sm" type="danger" class="section-content__remove"
                     v-on:click="showDeleteModal = true">Delete Item
        </base-button>
        <base-button size="sm" :type="isArchived ? 'success' : 'warning'" class="section-content__remove"
                     v-on:click="showArchiveModal = true">{{ isArchived ? 'Restore from Archive' : 'Archive Item' }}
        </base-button>
      </div>
    </form>
    <div class="container" v-if="['ianmorrison','superadmin'].indexOf($store.state.user.alias) > -1">
      <form v-on:submit.prevent="importFormSubmit" class="container">
        Import alias:
        <input type="text" name="import_url" label="Import URL" v-model="importURL" class="form-control d-inline-block"
               style="max-width: 400px"/>
        <input type="submit" value="Submit" class="btn btn-default"/>
      </form>
    </div>
    <DeleteItemModal v-model="showDeleteModal"
                     :entity="$store.state.formData.productForm"
                     :confirmCallback="deleteProduct"
                     :successRoute="'/' + routeBase + '/products'"/>
    <ArchiveItemModal v-model="showArchiveModal"
                      :entity="$store.state.formData.productForm"
                      :confirmCallback="toggleProductArchived"
                      :fromArchive="isArchived"/>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import {mapState} from "vuex";
import FileInput from '@/components/Inputs/FileInput'
import HtmlEditor from '@/components/Inputs/HtmlEditor'
import AltImagesGallery from '@/components/CORE/ContentSections/AltImagesGallery'
import DeleteItemModal from '@/components/CORE/Modals/DeleteItemModal'
import ArchiveItemModal from '@/components/CORE/Modals/ArchiveItemModal'
import ProductVariants from '@/components/CORE/ContentSections/ProductVariants'
import Tags from '@/components/CORE/Tags'

export default {
  name: 'product',
  components: {
    FileInput,
    ProductVariants,
    HtmlEditor,
    AltImagesGallery,
    DeleteItemModal,
    ArchiveItemModal,
    Tags
  },
  data() {
    return {
      submitEnabled: true,
      errorMessage: false,
      successMessage: false,
      showForm: false,
      showVariants: false,
      showDeleteModal: false,
      showArchiveModal: false,
      aliasSet: true,
      aliasAvailable: true,
      aliasTimeout: {},
      initialAlias: '',
      importURL: ''
    }
  },
  computed: {
    ...mapFields([
      'formData.productForm.status',
      'formData.productForm.title',
      'formData.productForm.alias',
      'formData.productForm.vendors',
      'formData.productForm.item_price',
      'formData.productForm.item_tax',
      'formData.productForm.item_tax2',
      'formData.productForm.image',
      'formData.productForm.alt_image',
      'formData.productForm.content',
      'formData.productForm.taxonomies',
      'formData.productForm.item_ots',
      'formData.productForm.item_decrement',
      'formData.productForm.item_ignore_ots',
      'formData.productForm.prep_time',
      'formData.productForm.sequence',
      'formData.productForm.subsidiaries',
      'formData.productForm.assigned_to'
    ]),
    ...mapState({
      config: state => state.config,
      allVendors: state => state.vendors,
      allProductTaxonomies: state => state.product_taxonomies,
      allVendorTaxonomies: state => state.vendor_taxonomies,
      allAssignees: state => state.product_assignees
    }),
    routeBase() {
      return this.$route.path.split('/')[1]
    },
    routePath() {
      return this.$route.path.split('/')[2]
    },
    formAction() {
      return 'product/' +
        (this.$store.state.formData.productForm.id !== '' ?
          this.$store.state.formData.productForm.id + '/update' : (this.routeBase === 'retailers' ? 'retailer-items/create' : 'items/create'))
    },
    isArchived() {
      return this.$store.state.formData.productForm.parent &&
        (this.$store.state.formData.productForm.parent.alias === 'item-archive' ||
          this.$store.state.formData.productForm.parent.alias === 'retail-item-archive')
    }
  },
  methods: {
    formSubmit(event) {
      this.submitEnabled = false
      let closure = this
      this.$store.dispatch('POST_TO_API', event).then(function (message) {
        closure.submitEnabled = true
        if (closure.routeBase === 'retailers')
          closure.$store.dispatch('LOAD_API', {
            url: 'products/retailer-items',
            name: 'retailerProducts'
          })
        else
          closure.$store.dispatch('LOAD_API', 'products');

        if (event.target.attributes.action.value.split('/')[2] === 'update') {
          closure.showFormMessage(true)
          closure.initializeForm()
        } else {
          closure.$store.commit('RESET_FORM_STATE', 'productForm')
          closure.$router.push('/' + closure.routeBase + '/product/' + message.id)
        }
      }).catch(function (message) {
        closure.showFormMessage(message)
        closure.submitEnabled = true
      })
    },
    showFormMessage(message) {
      if (message === true) {
        this.errorMessage = false
        this.successMessage = true
        let closure = this
        setTimeout(function () {
          closure.successMessage = false
        }, 2000)
      } else {
        this.errorMessage = message
      }
    },
    initializeForm() {
      if (this.allVendors.length === 0) {
        this.$store.dispatch('LOAD_API', 'vendors');
      }
      if (this.routeBase === 'retailers' && this.allVendorTaxonomies.length === 0)
        this.$store.dispatch('LOAD_API', {
          name: 'vendor_taxonomies',
          url: 'taxonomies/vendor?recursive=true&flat=true'
        });
      if (this.routeBase === 'shop' && this.allProductTaxonomies.length === 0)
        this.$store.dispatch('LOAD_API', {name: 'product_taxonomies', url: 'taxonomies/item'});

      if (this.allAssignees.length === 0) {
        this.$store.dispatch('LOAD_API', {name: 'product_assignees', url: 'group/product-lists'});
      }
      if (this.$route.params.id === 'new') {
        this.$store.commit('RESET_FORM_STATE', 'productForm')
        this.showForm = true
        this.aliasSet = false
      } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
        this.initialAlias = false
        let closure = this
        this.$store.dispatch('LOAD_API', {
          url: 'product/' + this.$route.params.id,
          name: 'formData',
          id: 'productForm',
          form: true
        }).then(function (data) {
          closure.showForm = true
          closure.initialAlias = data.alias
          closure.handleAliasChange(data.alias)
          if (data.item_flavour || data.item_size || data.subsidiaries) closure.showVariants = true
        });

      } else {
        this.showFormMessage('Product id not supplied. Please go back to products and try again.')
      }
    },
    deleteProduct(id) {
      return this.$store.dispatch('DELETE_ENTITY', {type: 'item', id: id})
    },
    toggleProductArchived(id) {
      const closure = this
      return this.$store.dispatch('MOVE_ENTITY', {
        type: 'item',
        id: id,
        to: this.isArchived ? 'items' : 'item-archive'
      }).then(function () {
        closure.initializeForm()
        closure.$store.dispatch('LOAD_API', {
          url: 'products/item-archive',
          name: 'archiveProducts'
        })
        closure.$store.dispatch('LOAD_API', 'products')
      })
    },
    handleAliasKeyup(e) {
      if (['Tab', 'Shift'].indexOf(e.key) === -1)
        this.aliasSet = e.target.value.length > 0
    },
    handleAliasBlur(e) {
      this.alias = this.slugify(e.target.value)
    },
    handleAliasChange(value) {
      clearTimeout(this.aliasTimeout)
      let closure = this
      if (value === this.initialAlias) {
        this.aliasAvailable = true
      } else if (this.initialAlias !== false) {
        this.aliasTimeout = setTimeout(function () {
          closure.checkAliasAvailability(value)
        }, 1000)
      }
    },
    checkAliasAvailability(value) {
      let closure = this
      if (value.length > 0)
        this.$store.dispatch('ALIAS_AVAILABLE', value).then(result => {
          closure.aliasAvailable = result.data
        })
      return true
    },
    importFormSubmit() {
      this.importFromAPI(this.importURL)
    },
    importFromAPI(alias) {
      let url = 'https://www.bayviewvillageshops.com/api/' + alias + '.json'
      let closure = this
      this.$store.dispatch('LOAD_API', {
        url: url,
        name: 'formData',
        id: 'tempForm'
      }).then(function (data) {

        closure.title = data.alt_item[0].title
        closure.alias = data.alt_item[0].alias
        closure.content = data.description
        closure.image = 'https://www.bayviewvillageshops.com' + data.alt_item[0].image
        closure.showForm = true

      });
    }
  },
  created() {
    this.initializeForm()
  },
  watch: {
    '$route.params.id': function (id) {
      this.initializeForm(id)
    },
    'title': function (data) {
      if (!this.aliasSet) {
        this.alias = this.slugify(data)
      }
    },
    'alias': function (value) {
      this.handleAliasChange(value)
    }
  }
}
</script>
<style lang="scss" scoped>
.product-image {
  display: block;
  max-width: 50%;
  margin: auto;
}

.alert-success {
  transition: .3s;
}

.loading-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alias.in-use {
  ::v-deep input {
    background-color: #FFDDDD;
  }

  ::v-deep label::after {
    content: 'in use';
    color: #f5365c;
    font-weight: bold;
  }

}
</style>
