<template>
  <modal :show.sync="showModal" modal-classes="modal-danger modal-dialog-centered">
    <h6 slot="header" class="modal-title" id="modal-title-notification">Delete Item</h6>

    <div class="py-3 text-center">
      <i class="fa fa-exclamation-triangle fa-3x"></i>
      <h4 class="heading mt-4">Are you sure you want to delete<br>"{{entity.title}}"?</h4>
      <p>You may not be able to undo this operation.</p>
    </div>
    <div class="text-center" v-if="formMessage">{{formMessage}}</div>
    <template slot="footer">
      <base-button type="link"
                   class="ml-auto text-white"
                   v-on:click="showModal = false">
        No, close.
      </base-button>
      <base-button type="white" v-on:click="handleConfirmClick" :disabled="processing">Yes, delete!</base-button>
    </template>
  </modal>
</template>
<script>
  export default {
    name: 'DeleteItemModal',
    props: {
      entity: Object,
      confirmCallback: Function,
      successRoute: String,
      value: Boolean
    },
    data() {
      return {
        processing: false,
        formMessage: false,
        showModal: false
      }
    },
    methods: {
      handleConfirmClick() {
        let closure = this
        this.processing = true
        this.confirmCallback(this.entity.id).then(function () {
          closure.processing = false
          closure.showModal = false
          if(closure.successRoute && closure.successRoute.length > 0)
            closure.$router.push(closure.successRoute)
        }).catch(function (data) {
          closure.formMessage = 'Error deleting item: ' + data
          closure.processing = false
        })
      }
    },
    watch: {
      'showModal': function (value) {
        this.$emit('input', value)
      },
      'value': function (value) {
        this.showModal = this.value
      }
    }
  }
</script>
