<template>
  <div class="product-variants">
    <div class="row mb-2 labels">
      <div class="col">Size</div>
      <div class="col">Flavour</div>
      <div class="col-6">
        <div class="row">
          <div class="col">Price</div>
          <div class="col" v-html="config.config_domain == 'shopwillowbrookcom' ? 'GST' : 'HST'"></div>
          <div class="col" v-if="config.config_domain == 'shopwillowbrookcom'">PST</div>
          <div class="col">Avail.</div>
        </div>
      </div>
    </div>
    <div class="row">
      <base-input class="col"
                  v-model="item_size"/>
      <base-input class="col"
                  v-model="item_flavour"/>
      <div class="col-6">
        <div class="row">
          <base-input prepend-icon="fas fa-dollar-sign"
                      class="col"
                      v-model="item_price" disabled/>
          <base-input prepend-icon="fas fa-dollar-sign"
                      class="col"
                      v-model="item_tax" disabled/>
          <base-input prepend-icon="fas fa-dollar-sign"
                      class="col"
                      v-model="item_tax2"
                      v-if="config.config_domain == 'shopwillowbrookcom'" disabled/>
          <base-input class="col"
                      v-model="item_ots" disabled/>
        </div>
      </div>
    </div>
    <div class="row" v-for="(subsidiary, index) in subsidiaries" :key="index">
      <base-input class="col"
                  v-on:change="handleSubChange()"
                  v-model="subsidiary.item_size"/>
      <base-input class="col"
                  v-on:change="handleSubChange()"
                  v-model="subsidiary.item_flavour"/>
      <div class="col-6">
        <div class="row">
          <base-input prepend-icon="fas fa-dollar-sign"
                      class="col"
                      v-on:change="handleSubChange()"
                      :placeholder="item_price"
                      v-model="subsidiary.item_price"/>
          <base-input prepend-icon="fas fa-dollar-sign"
                      class="col"
                      v-on:change="handleSubChange()"
                      :placeholder="item_tax"
                      v-model="subsidiary.item_tax"/>
          <base-input prepend-icon="fas fa-dollar-sign"
                      class="col"
                      v-on:change="handleSubChange()"
                      :placeholder="item_tax2"
                      v-model="subsidiary.item_tax2"
                      v-if="config.config_domain == 'shopwillowbrookcom'"/>
          <base-input class="col"
                      v-on:change="handleSubChange()"
                      :placeholder="0"
                      v-model="subsidiary.item_ots"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-right">
        <base-button size="sm" type="info" class="product-variants__add-variant"
                     v-on:click="newSub()">New Variant
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from "vuex";
  import {mapFields} from "vuex-map-fields";

  export default {
    name: 'ProductVariants',
    props: {
      value: Array
    },
    computed: {
      ...mapFields([
        'formData.productForm.item_price',
        'formData.productForm.item_tax',
        'formData.productForm.item_tax2',
        'formData.productForm.item_ots',
        'formData.productForm.item_size',
        'formData.productForm.item_flavour'
      ]),
      ...mapState({
        config: state => state.config
      }),
    },
    data() {
      return {
        subsidiaries: this.value
      }
    },
    methods: {
      handleSubChange: function () {
        // TODO: Only emit subsidiaries that have been populated
        this.$emit('input', this.subsidiaries)
      },
      newSub: function () {
        let tempId = 'new-' + Math.random().toString(36).replace(/[^A-Za-z1-9]+/g, '').substr(0, 5)
        this.subsidiaries.push({
          type: 'item',
          id: tempId
        })
      },
      updated() {
        this.subsidiaries = this.value
      }
    }
  }
</script>
<style lang="scss" scoped>
  .product-variants {
    .labels {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
</style>
