<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">
                <img v-if="logoSrc" class="login-logo" :src="require('../../../public/img/' + logoSrc)"/>
              </h1>
              <p class="text-lead text-white"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="model.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <!--<base-checkbox v-model="model.rememberMe">Remember me</base-checkbox>-->
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </form>
              </validation-observer>
              <div v-if="errorMessage" class="alert-danger">{{errorMessage}}</div>
            </div>
          </div>
          <!--<div class="row mt-3">
            <div class="col-6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </div>
            <div class="col-6 text-right">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        model: {
          email: '',
          password: '',
          rememberMe: false
        },
        errorMessage: ''
      }
    },
    computed: {
      logoSrc() {
        return this.$store.state.config.config_domain &&
        this.$store.state.config.config_domain !== 'default' ?
          this.$store.state.config.config_domain + '.png' : false
      }
    },
    methods: {
      onSubmit() {
        let closure = this
        this.$store.dispatch('LOG_USER_IN', {
          username: this.model.email,
          password: this.model.password
        }).then(function (result) {
          if (result.user) {
            let defaultLanding = '/shop/products'
            if(closure.$store.state.config.config_domain.indexOf('forvest') > -1)
              defaultLanding = '/pages'
            closure.$router.push(defaultLanding)
          } else {
            closure.errorMessage = 'There was a problem logging in'
          }
        }).catch(function (error) {
          closure.errorMessage = error
        })
      }
    },
    mounted() {
      if (!this.$route.query.status || this.$route.query.status !== 'loggedout') {
        this.$store.dispatch('VERIFY_SESSION')
      }
    }
  };
</script>
<style scoped>
  .login-logo {
    max-width: 200px;
  }
</style>
