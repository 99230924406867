<template>
  <div :class="['entity-selector', {expand: expand}]">
    <div class="search-wrapper">
      <div class="search-form">
        <label for="search">Search Products</label>
        <input id="search" class="form-control"
               v-model="search"
               v-on:focus="handleSearchFocus"
               v-on:blur="handleSearchBlur"
               autocomplete="off"/>
      </div>
      <div class="search-results">
        <div v-if="loading" class="result">Loading...</div>
        <div v-else-if="results !== false && results.length === 0 && search.length > 0" class="result">No Results</div>
        <div v-else v-for="(result, index) in results"
             class="result"
             v-on:click="handleSelect(result)"
             :key="index">
          <img :src="result.image"/>
          {{ result.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EntitySelector',
  data() {
    return {
      search: '',
      results: false,
      loading: false,
      searchTimeout: '',
      expand: false,
      expandLeft: false
    }
  },
  props: {
    expandOnFocus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSelect(data) {
      this.$emit('success', data)
    },
    handleSearchChange(value) {
      clearTimeout(this.searchTimeout)
      if (value.length === 0)
        this.results = false
      let closure = this
      this.searchTimeout = setTimeout(function () {
        closure.searchEntities(value)
      }, 1000)
    },
    searchEntities(value) {
      if (value.length > 0) {
        this.loading = true
        this.$store.dispatch('ENTITY_SEARCH', {
          route: 'products',
          parent: 'retailer-items',
          term: value
        }).then(result => {
          this.loading = false
          this.results = result.data
        })
      }
      return true
    },
    handleSearchFocus() {
      if(this.expandOnFocus === true)
        this.expand = true
    },
    handleSearchBlur() {
      this.expand = false
    }
  },
  watch: {
    search: function (value) {
      this.handleSearchChange(value)
    }
  }
}
</script>
<style lang="scss" scoped>
.entity-selector {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E3E3E3;
  transition: .3s;

  &.expand {
    width: 175%;
    height: 175%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 200;
  }

  .search-wrapper {
    padding: 20px;
    max-width: 300px;
    width: 85%;
    height: 100%;
  }

  .search-results {
    margin-left: 1%;
    width: 98%;
    background: #FFFFFF;
    max-height: calc(100% - 100px);
    overflow-y: auto;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);

    .result {
      padding: 10px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #F1F1F1;
      }

      img {
        width: 35px;
        height: 35px;
        border-radius: 7px;
        object-fit: contain;
      }

      &:hover {
        background: #F1F1F1;
      }
    }
  }
}
</style>
