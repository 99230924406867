<template>
  <div>
    <form v-on:submit.prevent="formSubmit" :action="formAction" id="vendorForm" enctype="multipart/form-data">
      <base-header class="pb-6 bg-default">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.params.id === 'new' ? 'Add' : 'Edit' }}
              {{ $route.name }}</h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <route-breadcrumb/>
            </nav>
          </div>
          <div class="col-lg-6 col-5 text-right">
            <base-button size="sm" type="neutral" v-on:click="$router.push('/'+routeBase+'/vendor/new')">New
            </base-button>
            <base-button size="sm" type="neutral" v-on:click="$router.go(-1)">Back</base-button>
            <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
          </div>
        </div>
        <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
          <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{ errorMessage }}</div>
          <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
        </div>
      </base-header>
      <div class="container-fluid mt--6 loading-container" v-if="!showForm">
        <div class="row">
          <div class="col-12">
            Loading...
          </div>
        </div>
      </div>
      <div class="container-fluid mt--6" v-else>
        <div class="row">
          <div class="col-lg-6">
            <div class="card-wrapper">
              <!-- Form controls -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header">
                  <h3 class="mb-0">Details</h3>
                </div>
                <!-- Card body -->
                <div class="card-body">
                  <base-input label="Title" placeholder="Title" v-model="title"/>
                  <base-input label="Alias" placeholder="Alias (e.g. vendor-title)" v-model="alias"/>
                  <label class="form-control-label">Description</label>
                  <html-editor v-model="content"></html-editor>
                  <label class="form-control-label">Hours</label>
                  <textarea class="form-control mb-4" v-model="store_hours"></textarea>
                  <base-input label="Website" placeholder="Website" v-model="website"/>
                  <base-input label="Phone" placeholder="Phone" v-model="phone"/>
                  <base-input label="Unit" placeholder="Unit" v-model="unit"/>
                  <Tags label="Categories" v-model="taxonomies" :options="allTaxonomies" placeholder="Add a category"/>
                  <Tags label="Lists" v-model="assigned_to" :options="allAssignees"
                        placeholder="Add retailer to a list"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Logo</h3>
                </div>
                <div class="card-body">
                  <img class="vendor-image mb-4" v-if="$store.state.formData.vendorForm.image"
                       :src="$store.state.formData.vendorForm.image"/>
                  <file-input name="image"></file-input>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Profile Image</h3>
                </div>
                <div class="card-body">
                  <img class="vendor-image mb-4"
                       v-if="$store.state.formData.vendorForm.alt_image && $store.state.formData.vendorForm.alt_image[0]"
                       :src="$store.state.formData.vendorForm.alt_image[0]"/>
                  <file-input name="alt_image"></file-input>
                </div>
              </div>
            </div>
            <div class="card-wrapper">
              <!-- Form controls -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header">
                  <h3 class="mb-0">Gastronomer</h3>
                </div>
                <!-- Card body -->
                <div class="card-body">
                  <div class="d-flex mb-4">
                    <base-switch v-model="ecommerce_vendor" type="default"/>
                    <label class="ml-3">Show on Gastronomer</label>
                  </div>
                  <base-input label="Emails" placeholder="Contact emails (comma separated)" v-model="addl_email"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteControls :entity="$store.state.formData.vendorForm" :successRoute="'/retailers'"/>
      </div>
    </form>
    <div class="container" v-if="['ianmorrison','superadmin'].indexOf($store.state.user.alias) > -1">
      <form v-on:submit.prevent="importFormSubmit" class="container">
        Import alias:
        <input type="text" name="import_url" label="Import URL" v-model="importURL" class="form-control d-inline-block"
               style="max-width: 400px"/>
        <input type="submit" value="Submit" class="btn btn-default"/>
      </form>
    </div>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import {mapState} from "vuex";
import FileInput from '@/components/Inputs/FileInput'
import HtmlEditor from '@/components/Inputs/HtmlEditor'
import Tags from '@/components/CORE/Tags'
import DeleteControls from '@/components/CORE/DeleteControls'

export default {
  name: 'vendor',
  components: {
    FileInput,
    HtmlEditor,
    Tags,
    DeleteControls
  },
  data() {
    return {
      submitEnabled: true,
      errorMessage: false,
      successMessage: false,
      showForm: false,
      importURL: '',
    }
  },
  computed: {
    ...mapFields([
      'formData.vendorForm.title',
      'formData.vendorForm.alias',
      'formData.vendorForm.content',
      'formData.vendorForm.taxonomies',
      'formData.vendorForm.website',
      'formData.vendorForm.phone',
      'formData.vendorForm.unit',
      'formData.vendorForm.store_hours',
      'formData.vendorForm.addl_email',
      'formData.vendorForm.ecommerce_vendor',
      'formData.vendorForm.image',
      'formData.vendorForm.alt_image',
      'formData.vendorForm.assigned_to'
    ]),
    ...mapState({
      products: state => state.products,
      allTaxonomies: state => state.vendor_taxonomies,
      allAssignees: state => state.product_assignees
    }),
    routeBase() {
      return this.$route.path.split('/')[1]
    },
    formAction() {
      return 'profile/' +
        (this.$store.state.formData.vendorForm.id !== '' ?
          this.$store.state.formData.vendorForm.id + '/update' : 'vendors/create')
    }
  },
  methods: {
    formSubmit(event) {
      this.submitEnabled = false
      let closure = this
      this.$store.dispatch('POST_TO_API', event).then(function (message) {
        closure.submitEnabled = true
        closure.$store.dispatch('LOAD_API', 'vendors');
        if (event.target.attributes.action.value.split('/')[2] === 'update') {
          closure.showFormMessage(true)
          closure.initializeForm()
        } else {
          closure.$store.commit('RESET_FORM_STATE', 'vendorForm')
          closure.$router.push('/' + closure.routeBase + '/vendor/' + message.id)
        }
      }).catch(function (message) {
        closure.showFormMessage(message)
        closure.submitEnabled = true
      })
    },
    showFormMessage(message) {
      if (message === true) {
        this.errorMessage = false
        this.successMessage = true
        let closure = this
        setTimeout(function () {
          closure.successMessage = false
        }, 2000)
      } else {
        this.errorMessage = message
      }
    },
    initializeForm() {
      if (this.products.length === 0) {
        //this.$store.dispatch('LOAD_API', 'products');
      }
      if (this.allTaxonomies.length === 0) {
        this.$store.dispatch('LOAD_API', {
          name: 'vendor_taxonomies',
          url: 'taxonomies/vendor?recursive=true&flat=true'
        });
      }
      if (this.allAssignees.length === 0) {
        this.$store.dispatch('LOAD_API', {name: 'product_assignees', url: 'group/product-lists'});
      }
      if (this.$route.params.id === 'new') {
        this.$store.commit('RESET_FORM_STATE', 'vendorForm')
        this.showForm = true
      } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
        let closure = this
        this.$store.dispatch('LOAD_API', {
          url: 'profile/' + this.$route.params.id,
          name: 'formData',
          id: 'vendorForm',
          form: true
        }).then(function () {
          closure.showForm = true
        });
      } else {
        this.showFormMessage('Vendor id not supplied. Please go back to vendors and try again.')
      }
    },
    importFormSubmit() {
      this.importFromAPI(this.importURL)
    },
    importFromAPI(alias) {
      let url = 'https://www.bayviewvillageshops.com/api/' + alias + '.json'
      let closure = this
      this.$store.dispatch('LOAD_API', {
        url: url,
        name: 'formData',
        id: 'tempForm'
      }).then(function (data) {

        closure.title = data.title
        closure.alias = data.alias
        closure.content = data.description
        closure.website = data.website
        closure.phone = data.phone
        closure.unit = data.location
        closure.image = 'https://www.bayviewvillageshops.com' + data.image
        closure.alt_image = ['https://www.bayviewvillageshops.com' + data.alt_image[0].replace('.14.', '.')]
        closure.store_hours = data.store_hours.join("\r\n");

        if (data.categories && data.categories.length > 0) {
          for (let i = 0; i < data.categories.length; i++) {
            closure.taxonomies[i] = {
              title: data.categories[i].title,
              id: data.categories[i].alias
            }
          }
        }

        closure.showForm = true

      });
    }
  },
  created() {
    this.initializeForm()
  },
  watch: {
    '$route.params.id': function (id) {
      this.initializeForm(id)
    }
  }
}
</script>
<style lang="scss" scoped>
.vendor-image {
  display: block;
  max-width: 60%;
  margin: auto;
}

.alert-success {
  transition: .3s;
}

.loading-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
