import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import COREAuthLayout from '@/views/CORE/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/CORE/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const UserProfile = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');

// CORE Dashboard pages
const Message = () => import(/* webpackChunkName: "files" */ '@/views/CORE/Message.vue');
const Messages = () => import(/* webpackChunkName: "files" */ '@/views/CORE/Messages.vue');
const File = () => import(/* webpackChunkName: "files" */ '@/views/CORE/File.vue');
const Files = () => import(/* webpackChunkName: "files" */ '@/views/CORE/Files.vue');
const Products = () => import(/* webpackChunkName: "products" */ '@/views/CORE/Products.vue');
const Product = () => import(/* webpackChunkName: "products" */ '@/views/CORE/Product.vue');
const Events = () => import(/* webpackChunkName: "events" */ '@/views/CORE/Events.vue');
const Event = () => import(/* webpackChunkName: "events" */ '@/views/CORE/Event.vue');
const Collection = () => import(/* webpackChunkName: "collection" */ '@/views/CORE/Collection.vue');
const CollectionItem = () => import(/* webpackChunkName: "collection" */ '@/views/CORE/CollectionItem.vue');
const Profiles = () => import(/* webpackChunkName: "profile" */ '@/views/CORE/Profiles.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ '@/views/CORE/Profile.vue');
const Vendors = () => import(/* webpackChunkName: "vendors" */ '@/views/CORE/Vendors.vue');
const Vendor = () => import(/* webpackChunkName: "vendors" */ '@/views/CORE/Vendor.vue');
const Reports = () => import(/* webpackChunkName: "vendors" */ '@/views/CORE/Reports.vue');
const Users = () => import(/* webpackChunkName: "users" */ '@/views/CORE/Users.vue');
const User = () => import(/* webpackChunkName: "users" */ '@/views/CORE/User.vue');
const APIAuth = () => import(/* webpackChunkName: "users" */ '@/views/CORE/APIAuth.vue');
const Pages = () => import(/* webpackChunkName: "vendors" */ '@/views/CORE/Pages.vue');
const List = () => import(/* webpackChunkName: "pages" */ '@/views/CORE/List.vue');
const Page = () => import(/* webpackChunkName: "pages" */ '@/views/CORE/Page.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/CORE/Login.vue');

let settingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings/templates/email-templates',
  name: 'Settings',
  children: [
    {
      path: 'users',
      name: 'Users',
      component: Users
    },
    {
      path: 'user/:id?',
      name: 'User',
      component: User
    },
    {
      path: 'apiauth',
      name: 'API Authentications',
      component: APIAuth
    },
    {
      path: 'templates/:id',
      name: 'Email Templates',
      component: Messages
    },
    {
      path: 'template/:id/:parent?',
      name: 'Email Template',
      component: Message
    },
    {
      path: 'template-component/:id',
      name: 'Template Component',
      component: Message
    }
  ]
}

let listMenu = {
  path: '/list',
  component: DashboardLayout,
  name: 'List',
  children: [
    {
      path: ':id',
      name: 'List Assignments',
      component: List
    }
  ]
}

let mediaMenu = {
  path: '/media',
  component: DashboardLayout,
  redirect: '/media/drop-box',
  name: 'Media',
  children: [
    {
      path: ':id',
      name: 'Files',
      component: Files
    },
    {
      path: 'file/:id/:parent?',
      name: 'File',
      component: File
    }
  ]
}

let shopMenu = {
  path: '/shop',
  component: DashboardLayout,
  redirect: '/shop/products',
  name: 'Shop',
  children: [
    {
      path: 'products',
      name: 'Products',
      component: Products
    },
    {
      path: 'archive',
      name: 'Archive',
      component: Products
    },
    {
      path: 'product/:id?',
      name: 'Product',
      component: Product
    },
    {
      path: 'vendors',
      name: 'Vendors',
      component: Vendors
    },
    {
      path: 'vendor/:id?',
      name: 'Vendor',
      component: Vendor
    },
    {
      path: 'reports',
      name: 'Reports',
      component: Reports
    },
  ]
}

let retailersMenu = {
  path: '/retailers',
  component: DashboardLayout,
  name: 'Retailers',
  children: [
    {
      path: 'products',
      name: 'Products',
      component: Products
    },
    {
      path: 'product/:id?',
      name: 'Product',
      component: Product
    },
    {
      path: ':id?',
      name: 'Retail Shops',
      component: Vendors
    },
    {
      path: 'vendor/:id?',
      name: 'Vendor',
      component: Vendor
    }
  ]
}

let eventsMenu = {
  path: '/calendar',
  component: DashboardLayout,
  redirect: '/calendar/events',
  name: 'Events',
  children: [
    {
      path: 'event/:id/:parent?',
      name: 'Event',
      component: Event
    },
    {
      path: ':id?',
      name: 'Event Group',
      component: Events
    },
  ]
}

let collectionMenu = {
  path: '/collection',
  component: DashboardLayout,
  redirect: '/collection/default',
  name: 'Collection Group',
  children: [
    {
      path: 'collection-item/:id/:parent?',
      name: 'Collection Item',
      component: CollectionItem
    },
    {
      path: ':id?',
      name: 'Collection',
      component: Collection
    },
  ]
}

let profileMenu = {
  path: '/profiles',
  component: DashboardLayout,
  redirect: '/profiles',
  name: 'Profiles Group',
  children: [
    {
      path: '/profile/:id/:parent?',
      name: 'Profile',
      component: Profile
    },
    {
      path: ':id?',
      name: 'Profiles',
      component: Profiles
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  children: [
    {
      path: '/page/:id/:parent?',
      name: 'Page',
      component: Page
    },
    {
      path: ':id?',
      name: 'Pages List',
      component: Pages
    }
  ]
}

let articlesMenu = {
  path: '/articles',
  component: DashboardLayout,
  name: 'Articles',
  children: [
    {
      path: '/article/:id/:parent?',
      name: 'Article',
      component: Page
    },
    {
      path: ':id?',
      name: 'Blog List',
      component: Pages
    }
  ]
}

let COREAuthPages = {
  path: '/',
  component: COREAuthLayout,
  name: 'COREAuthentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {path: '*', component: NotFound}
  ]
};

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/elements',
  name: 'Forms',
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component: FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component: FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component: FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let demoPagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: UserProfile
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/home',
      name: 'AuthHome',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Home'
  },
  settingsMenu,
  mediaMenu,
  listMenu,
  shopMenu,
  retailersMenu,
  eventsMenu,
  collectionMenu,
  profileMenu,
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  articlesMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  authPages,
  COREAuthPages
];

export default routes;
