<template>
  <main class="page-editor">
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Facebook OpenGraph</h3>
              </div>
              <div class="card-body">
                <button class="btn btn-default" v-on:click="authenticateWithFacebook">Authenticate with Facebook</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Aisle Labs</h3>
              </div>
              <div class="card-body">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Moneris</h3>
              </div>
              <div class="card-body">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Other</h3>
              </div>
              <div class="card-body">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
  import Facebook from '@/helpers/facebook'

  export default {
    name: 'APIAuth',
    methods: {
      authenticateWithFacebook() {
        Facebook.init()
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
