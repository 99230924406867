<template>
  <div>
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Profiles</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" v-on:click="$router.push('/profile/new/' + $route.params.id)">New</base-button>
          <!--<base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6" v-if="profiles">
      <div class="row">
        <div class="col">
          <profiles-table :items="profiles"/>
        </div>
      </div>
    </div>
    <Loader v-else/>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import ProfilesTable from "@/views/CORE/ProfilesTable";

export default {
  components: {
    ProfilesTable,
    RouteBreadCrumb,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      profileGroups: state => state.profiles,
    }),
    profiles() {
      return this.profileGroups[this.$route.params.id] || false
    }
  },
  mounted() {
    this.$store.dispatch('LOAD_API', {
      name: 'profiles',
      id: this.$route.params.id,
      url: 'profiles/' + this.$route.params.id
    });
  }
};
</script>
