<template>
  <div :class="['loader', {show: show}]">
    <div class="loading">
      <p>Loading&nbsp;</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  data() {
    return {
      show: false
    }
  },
  created() {
    let closure = this
    setTimeout(function(){ closure.show = true }, 500);
  }
}
</script>
<style lang="scss" scoped>
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  transition: .3s;
  opacity: 0;
  min-height: 70vh;
  color: #050A12;
  font-weight: bold;
}
.loader.show {
  opacity: 1;
}

.loading {
  width: 100px;
  p:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
</style>
