<template>
  <div class="drop"
       :class="getClasses"
       @dragover.prevent="dragOver"
       @dragleave.prevent="dragLeave"
       @drop.prevent="drop($event)">
    <img :src="imageSource" v-if="imageSource"/>
    <h3 v-if="wrongFile">Wrong file type</h3>
    <div v-if="!imageSource && !isDragging && !wrongFile && showInstructions">
      <h3 class="text-center">Drag & drop an image</h3>
      <div class="manual">
        <label>
          <p>or pick from device</p>
          <input type="file" :accept="'image/*'" ref="uploadFile" multiple @change="requestUploadFile">
        </label>
      </div>
    </div>
    <div :class="['upload-mask', {uploading: uploading}]">
      <div class="loader"></div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'ImageDrop',
    props: {
      showInstructions: Boolean
    },
    data() {
      return {
        isDragging: false,
        wrongFile: false,
        imageSource: null,
        uploading: false
      }
    },
    computed: {
      getClasses() {
        return {isDragging: this.isDragging}
      }
    },
    methods: {
      dragOver() {
        this.isDragging = true
      },
      dragLeave() {
        this.isDragging = false
      },
      drop(e) {
        let files = e.dataTransfer.files
        this.wrongFile = false
        // allows only 1 file
        if (files.length === 1) {
          let file = files[0]
          // allows image only
          if (file.type.indexOf('image/') >= 0) {
            let reader = new FileReader()
            reader.onload = f => {
              this.imageSource = f.target.result
              this.isDragging = false
            }
            reader.readAsDataURL(file)
            this.handleUpload(file)
          } else {
            this.wrongFile = true
            this.imageSource = null
            this.isDragging = false
          }
        }
      },
      requestUploadFile() {
        let src = this.$refs.uploadFile
        this.drop({dataTransfer: src})
      },
      handleUpload(file) {
        this.uploading = true
        let closure = this
        this.$store.dispatch('UPLOAD_FILE', file).then(function (uploadFile) {

          closure.$store.dispatch('LOAD_API', { url: '/media/' + uploadFile.id, setstate: false }).then(function (data) {
            if (data.type === 'file') {
              closure.imageSource = false
              closure.uploading = false
              closure.$emit('success', data)
            }
          })

        }).catch(function (error) {
          console.log(error)
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .drop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .2s ease-in-out;
  }

  .isDragging {
    background-color: #999;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h3 {
    max-width: 100px;
    margin: 30px auto;
  }

  p {
    font-size: 0.9rem;
  }

  .manual {
    text-align: center;
    font-size: .8rem;
    text-decoration: underline;
    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }

  .upload-mask {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);

    .loader {
      border: 10px solid rgba(255, 255, 255, 0.5); /* Light grey */
      border-top: 10px solid #FFFFFF; /* Blue */
      border-radius: 50%;
      width: 75px;
      height: 75px;
      animation: spin .75s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &.uploading {
      visibility: visible;
    }
  }
</style>
