<template>
  <form v-on:submit.prevent="formSubmit" :action="formAction" id="eventForm" enctype="multipart/form-data">
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.params.id === 'new' ? 'Add' : 'Edit'}}
            {{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button v-if="$route.params.id !== 'new'" size="sm" type="neutral"
                       v-on:click="$router.push('/calendar/event/new/' + parent.alias)">New
          </base-button>
          <base-button v-if="$route.params.id !== 'new'" size="sm" type="neutral"
                       v-on:click="$router.go(-1)">Back
          </base-button>
          <base-button v-else size="sm" type="neutral" v-on:click="$router.push('/calendar/' + $route.params.parent)">
            Back
          </base-button>
          <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
        </div>
      </div>
      <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
        <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{errorMessage}}</div>
        <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
      </div>
    </base-header>
    <div class="container-fluid mt--6 loading-container" v-if="!showForm">
      <div class="row">
        <div class="col-12">
          Loading...
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" v-else>
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Details</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-input label="Title" placeholder="Event title" v-model="title"/>
                <base-input label="Alias" placeholder="Alias (e.g. event-title)" v-model="alias"/>
                <base-input label="Start Date/Time" type="datetime-local" id="start-datetime" v-model="start"/>
                <base-input label="End Date/Time" type="datetime-local" id="end-datetime" v-model="end"/>
                <base-input label="Publish Date/Time" type="datetime-local" id="publish-datetime" v-model="publish"/>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Additional Details</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <Tags label="Vendor(s)" v-model="vendors" :options="allVendors" placeholder="Add a vendor"/>
                <base-input label="External Website Link" placeholder="URL (e.g. Zoom)" v-model="website"/>
                <base-input label="YouTube Id" placeholder="YouTube Id Id" v-model="youtube_id"/>
                <base-input label="Vimeo Id" placeholder="Vimeo Id" v-model="vimeo_id"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Image</h3>
              </div>
              <div class="card-body">
                <img class="event-image mb-4" v-if="$store.state.formData.eventForm.image"
                     :src="$store.state.formData.eventForm.image"/>
                <file-input name="image"></file-input>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Description</h3>
              </div>
              <div class="card-body">
                <html-editor v-model="content"></html-editor>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Attachments</h3>
              </div>
              <div class="card-body">
                <file-input name="attachment"></file-input>
                <div class="attachments" v-if="$store.state.formData.eventForm.attachment.length > 0">
                  <div class="attachment" v-for="attachment in $store.state.formData.eventForm.attachment"
                       :key="attachment.id">
                    <i class="far fa-file"></i>
                    <a :href="attachment.url" target="_blank">{{attachment.filename}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import {mapFields} from 'vuex-map-fields'
  import {mapState} from "vuex";
  import FileInput from '@/components/Inputs/FileInput'
  import HtmlEditor from '@/components/Inputs/HtmlEditor'
  import Tags from '@/components/CORE/Tags'

  export default {
    name: 'event',
    components: {
      FileInput,
      HtmlEditor,
      Tags
    },
    data() {
      return {
        submitEnabled: true,
        errorMessage: false,
        successMessage: false,
        showForm: false,
        parent: false
      }
    },
    computed: {
      ...mapFields([
        'formData.eventForm.title',
        'formData.eventForm.alias',
        'formData.eventForm.vendor',
        'formData.eventForm.content',
        'formData.eventForm.start',
        'formData.eventForm.end',
        'formData.eventForm.publish',
        'formData.eventForm.vendors',
        'formData.eventForm.website',
        'formData.eventForm.vimeo_id',
        'formData.eventForm.youtube_id'
      ]),
      ...mapState({
        allVendors: state => state.vendors,
      }),
      formAction() {
        return 'event/' +
          (this.$store.state.formData.eventForm.id !== '' ?
            this.$store.state.formData.eventForm.id + '/update' : this.$route.params.parent + '/create')
      }
    },
    methods: {
      formSubmit(event) {
        this.submitEnabled = false
        let closure = this
        this.$store.dispatch('POST_TO_API', event).then(function (message) {
          closure.submitEnabled = true
          closure.$store.dispatch('LOAD_API', 'events/' + closure.$route.params.id);
          if (event.target.attributes.action.value.split('/')[2] === 'update') {
            closure.showFormMessage(true)
            closure.initializeForm()
          } else {
            closure.$store.commit('RESET_FORM_STATE', 'eventForm')
            closure.$router.push('/calendar/event/' + message.id)
          }
        }).catch(function (message) {
          closure.showFormMessage(message)
          closure.submitEnabled = true
        })
      },
      showFormMessage(message) {
        if (message === true) {
          this.errorMessage = false
          this.successMessage = true
          let closure = this
          setTimeout(function () {
            closure.successMessage = false
          }, 2000)
        } else {
          this.errorMessage = message
        }
      },
      initializeForm() {

        if (this.$route.params.id === 'new') {
          this.$store.commit('RESET_FORM_STATE', 'eventForm')
          this.showForm = true
        } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
          let closure = this
          this.$store.dispatch('LOAD_API', {
            url: 'event/' + this.$route.params.id,
            name: 'formData',
            id: 'eventForm',
            form: true
          }).then(function (data) {
            if (data.start)
              closure.$store.commit('SET_FORM_STATE',
                {name: 'eventForm', field: 'start', data: data.start.split('+')[0]}
              )
            if (data.end)
              closure.$store.commit('SET_FORM_STATE',
                {name: 'eventForm', field: 'end', data: data.end.split('+')[0]}
              )
            if (data.publish)
              closure.$store.commit('SET_FORM_STATE',
                {name: 'eventForm', field: 'publish', data: data.publish.split('+')[0]}
              )
            closure.parent = data.parent
            closure.showForm = true
          });

        } else {
          this.showFormMessage('Event id not supplied. Please go back to events and try again.')
        }

        if (this.allVendors.length === 0) {
          this.$store.dispatch('LOAD_API', 'vendors');
        }
      }
    },
    created() {
      this.initializeForm()
    },
    watch: {
      '$route.params.id': function (id) {
        this.initializeForm(id)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .event-image {
    display: block;
    max-width: 50%;
    margin: auto;
  }

  .alert-success {
    transition: .3s;
  }

  .loading-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .attachments {
    margin-top: 15px;

    .attachment {
      i {
        margin-right: 5px;
      }

      a {
        font-size: 0.9rem;
        color: #777777;
      }

      margin-bottom: 10px;
    }
  }
</style>
