<template>
  <div class="card">
    <div class="border-0 card-header">
      <div class="float-right" v-if="newOrder.length > 0">
        <span class="text-danger">Sort order changed.</span>
        <base-button type="neutral" class="ml-3 py-0 px-3" v-on:click="saveSortOrder">Save Sort Order</base-button>
        <base-button type="white" class="ml-1 py-0 px-3" v-on:click="resetSortOrder">Restore</base-button>
      </div>
      <h3 class="mb-0">All Products</h3>
    </div>
    <el-table-draggable handle=".handle" direction="vertical" v-on:drop="handleDrop">
      <el-table class="table-responsive table-flush"
                header-row-class-name="thead-light"
                :data="itemsCopy">
        <el-table-column label="Product"
                         min-width="310px"
                         prop="name"
                         sortable>
          <template v-slot="{row}">
            <div class="media align-items-center">
              <i class="handle mr-3 fa fa-grip-vertical" v-if="parent==='items'"></i>
              <a :href="'#/'+ routeBase +'/product/' + row.id" class="avatar mr-3">
                <img :src="row.image"/>
              </a>
              <div class="media-body">
                <a :href="'#/'+ routeBase +'/product/' + row.id">
                  <span class="font-weight-600 name mb-0 text-sm"><strong
                    v-if="row.status === 2">DRAFT - </strong>{{ row.title }}</span>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Vendors"
                         min-width="200px"
                         prop="vendor"
                         sortable>
          <template v-slot="{row}">
            <a v-if="row.vendors"
               v-for="(vendor, index) in row.vendors" :href="'#/'+ routeBase +'/vendor/' + vendor.id"
               class="vendor-link"
               :key="index">
              <span class="status">{{ vendor.title }}</span>
            </a>
          </template>
        </el-table-column>

        <el-table-column label="Price"
                         prop="item_price"
                         min-width="140px"
                         v-if="routeBase !== 'retailers'">
          <template v-slot="{row}">
            {{ row.item_price | currency }}
          </template>
        </el-table-column>

        <el-table-column label="Tax"
                         prop="tax"
                         min-width="140px"
                         v-if="routeBase !== 'retailers'">
          <template v-slot="{row}">
            {{ row.item_tax + (row.item_tax2 || 0) | currency }}
          </template>
        </el-table-column>

        <el-table-column min-width="120px">
          <template v-slot="{row}">
            <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
              <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                <a class="dropdown-item" :href="'#/'+ routeBase +'/product/' + row.id">Edit Product</a>
                <a v-if="row.vendor" class="dropdown-item" :href="'#/'+ routeBase +'/vendor/'+row.vendor.id">View
                  Vendor</a>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>

    <div class="card-footer py-4 d-flex justify-content-end">
      <!--<base-pagination v-model="currentPage" :total="50"></base-pagination>-->
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import ElTableDraggable from 'element-ui-el-table-draggable'

export default {
  name: 'products-table',
  props: {
    parent: [String, Boolean],
    items: [Array, Object],
    default: []
  },
  components: {
    ElTableDraggable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      currentPage: 1,
      newOrder: [],
      itemsOriginal: JSON.parse(JSON.stringify(this.items)),
      itemsCopy: JSON.parse(JSON.stringify(this.items))
    };
  },
  computed: {
    routeBase() {
      return this.$route.path.split('/')[1]
    }
  },
  methods: {
    handleDrop(drop) {
      this.newOrder = drop.list
    },
    saveSortOrder() {
      if (!this.parent)
        return false;

      const newOrder = JSON.parse(JSON.stringify(this.newOrder))
      const newSortOrderArray = []
      for (let i = 0; i < newOrder.length; i++) {
        let entity = newOrder[i]
        newSortOrderArray.push({
          id: entity.id,
          sequence: i + 1
        })
      }

      this.$store.dispatch('UPDATE_SORT_ORDER', {
        parent: this.parent,
        new_sort_order: newSortOrderArray
      })

      this.itemsOriginal = newOrder
      this.newOrder = []
    },
    resetSortOrder() {
      this.itemsCopy = JSON.parse(JSON.stringify(this.itemsOriginal))
      this.newOrder = []
    }
  }
}
</script>
<style lang="scss">
.vendor-link {
  display: block;
}

.handle {
  cursor: move;

  &:active {
    cursor: grab;
  }

  color: #BBBBBB;
  display: inline-block;
}

div.el-table .el-table__row:hover.sortable-chosen {
  background: #FFFFFF;
}

div.el-table .el-table__row.sortable-ghost {
  opacity: 0;
}
</style>
