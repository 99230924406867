<template>
  <div>
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Vendors</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" v-on:click="$router.push('/'+routeBase+'/vendor/new')">New</base-button>
          <!--<base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <Loader v-if="loading"/>
    <div class="container-fluid mt--6" v-else>
      <div class="row">
        <div class="col">
          <vendors-table :items="vendors"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from "vuex";
  import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import VendorsTable from "@/views/CORE/VendorsTable";

  export default {
    components: {
      VendorsTable,
      RouteBreadCrumb,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapState({
        ecommerceVendors: state => state.ecommerceVendors,
        retailerVendors: state => state.vendors,
        config: state => state.config
      }),
      vendors() {
        return this.routeBase === 'retailers' ? this.retailerVendors : this.ecommerceVendors
      },
      routeBase() {
        return this.$route.path.split('/')[1]
      }
    },
    methods: {
      initializeTable: function () {
        let closure = this
        if ((closure.loading || this.ecommerceVendors.length === 0) && this.routeBase === 'shop')
          this.$store.dispatch('LOAD_API', {
            url: this.config.config_domain === 'shopwillowbrookcom' ? 'vendors/?sort=title' : 'vendors/?filter=ecommerce&sort=title',
            name: 'ecommerceVendors'
          }).then(function() {
            closure.loading = false
          });

        else if (this.retailerVendors.length === 0)
          this.$store.dispatch('LOAD_API', { url:'vendors?sort=title', name: 'vendors'}).then(function() {
            closure.loading = false
          });

        else
          closure.loading = false
      }
    },
    created() {
      this.initializeTable()
    },
    watch: {
      '$route.path': function () {
        this.initializeTable()
      }
    }
  };
</script>
