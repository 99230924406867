var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.items}},[_c('el-table-column',{attrs:{"label":"Product","min-width":"275px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[(row.image)?_c('a',{staticClass:"avatar mr-3",attrs:{"href":'#/calendar/event/' + row.id}},[_c('img',{attrs:{"src":row.image}})]):_vm._e(),_c('div',{staticClass:"media-body"},[_c('a',{attrs:{"href":'#/calendar/event/' + row.id}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.title))])])])])]}}])}),_c('el-table-column',{attrs:{"label":"Retailers","min-width":"200px","prop":"vendors","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.vendors),function(vendor,index){return (row.vendors)?_c('a',{key:index,staticClass:"vendor-link",attrs:{"href":'#/'+ _vm.routeBase +'/vendor/' + vendor.id}},[_c('span',{staticClass:"status"},[_vm._v(_vm._s(vendor.title))])]):_vm._e()})}}])}),_c('el-table-column',{attrs:{"label":"Start Time","prop":"start","min-width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.start)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"End Time","prop":"end","min-width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.end)+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":'#/calendar/event/'+row.id}},[_vm._v("Edit Event")])])],1)]}}])})],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-end"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("All Events")])])}]

export { render, staticRenderFns }