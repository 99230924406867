<template>
  <div class="section-content">
    <base-input placeholder="Youtube ID or Embed Code" v-model="videoId" v-on:change="handleChange()"/>
    <youtube v-if="videoId && videoId.length === 11" :video-id="videoId" class="video-player"></youtube>
    <div v-else-if="videoId.indexOf('iframe') > -1" v-html="videoId" class="video-player"></div>
  </div>
</template>
<script>
export default {
  name: 'VideoEmbed',
  props: {
    value: String
  },
  data() {
    return {
      videoId: this.value
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.videoId)
    }
  }
}
</script>
<style lang="scss" scoped>
  .video-player,
  ::v-deep .video-player iframe {
    width: 100%;
  }
</style>
