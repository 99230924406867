<template>
  <form v-on:submit.prevent="formSubmit" :action="formAction" id="profileForm" enctype="multipart/form-data">
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.params.id === 'new' ? 'Add' : 'Edit'}}
            {{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button v-if="$route.params.id !== 'new'" size="sm" type="neutral"
                       v-on:click="$router.push('/profile/new/' + parent.alias)">New
          </base-button>
          <base-button v-if="$route.params.id !== 'new'" size="sm" type="neutral"
                       v-on:click="$router.go(-1)">Back
          </base-button>
          <base-button v-else size="sm" type="neutral" v-on:click="$router.push('/profiles/' + $route.params.parent)">
            Back
          </base-button>
          <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
        </div>
      </div>
      <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
        <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{errorMessage}}</div>
        <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
      </div>
    </base-header>
    <div class="container-fluid mt--6 loading-container" v-if="!showForm">
      <div class="row">
        <div class="col-12">
          Loading...
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" v-else>
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Details</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-input label="Name" placeholder="Profile name" v-model="title"/>
                <base-input label="Job Title" placeholder="Job title / occupation" v-model="job_title"/>
                <Tags label="Categories" v-model="taxonomies" :options="allTaxonomies" placeholder="Add a category"/>
                <base-input label="External Link" placeholder="https://www.example.com" v-model="website"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Image</h3>
              </div>
              <div class="card-body">
                <img class="profile-image mb-4" v-if="$store.state.formData.profileForm.image"
                     :src="$store.state.formData.profileForm.image"/>
                <file-input name="image"></file-input>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Description</h3>
              </div>
              <div class="card-body">
                <html-editor v-model="content"></html-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import {mapFields} from 'vuex-map-fields'
  import {mapState} from "vuex";
  import FileInput from '@/components/Inputs/FileInput'
  import HtmlEditor from '@/components/Inputs/HtmlEditor'
  import Tags from '@/components/CORE/Tags'

  export default {
    name: 'profile',
    components: {
      FileInput,
      HtmlEditor,
      Tags
    },
    data() {
      return {
        submitEnabled: true,
        errorMessage: false,
        successMessage: false,
        showForm: false,
        parent: false
      }
    },
    computed: {
      ...mapFields([
        'formData.profileForm.title',
        'formData.profileForm.job_title',
        'formData.profileForm.taxonomies',
        'formData.profileForm.website',
        'formData.profileForm.vendor',
        'formData.profileForm.content',
        'formData.profileForm.start',
        'formData.profileForm.end'
      ]),
      ...mapState({
        vendors: state => state.vendors,
        allTaxonomies: state => state.profile_taxonomies
      }),
      formAction() {
        return 'profile/' +
          (this.$store.state.formData.profileForm.id !== '' ?
            this.$store.state.formData.profileForm.id + '/update' : this.$route.params.parent + '/create')
      }
    },
    methods: {
      formSubmit(event) {
        this.submitEnabled = false
        let closure = this
        this.$store.dispatch('POST_TO_API', event).then(function (message) {
          closure.submitEnabled = true
          closure.$store.dispatch('LOAD_API', 'profiles/' + closure.$route.params.parent);
          if (event.target.attributes.action.value.split('/')[2] === 'update') {
            closure.showFormMessage(true)
            closure.initializeForm()
          } else {
            closure.$store.commit('RESET_FORM_STATE', 'profileForm')
            closure.$router.push('/profile/' + message.id)
          }
        }).catch(function (message) {
          closure.showFormMessage(message)
          closure.submitEnabled = true
        })
      },
      showFormMessage(message) {
        if (message === true) {
          this.errorMessage = false
          this.successMessage = true
          let closure = this
          setTimeout(function () {
            closure.successMessage = false
          }, 2000)
        } else {
          this.errorMessage = message
        }
      },
      initializeForm() {
        if (this.allTaxonomies.length === 0) {
          this.$store.dispatch('LOAD_API', {name: 'profile_taxonomies', url: 'taxonomies/team-member'});
        }
        if (this.$route.params.id === 'new') {
          this.$store.commit('RESET_FORM_STATE', 'profileForm')
          this.showForm = true
        } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
          let closure = this
          this.$store.dispatch('LOAD_API', {
            url: 'profile/' + this.$route.params.id,
            name: 'formData',
            id: 'profileForm',
            form: true
          }).then(function (data) {
            closure.parent = data.parent
            closure.showForm = true
          });

        } else {
          this.showFormMessage('Profile item not supplied. Please go back to profile and try again.')
        }
      }
    },
    created() {
      this.initializeForm()
    },
    watch: {
      '$route.params.id': function (id) {
        this.initializeForm(id)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .profile-image {
    display: block;
    max-width: 50%;
    margin: auto;
  }

  .alert-success {
    transition: .3s;
  }

  .loading-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
