<template>
  <form v-on:submit.prevent="formSubmit" :action="formAction" id="reportsForm" enctype="multipart/form-data">
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.params.id === 'new' ? 'Add' : 'Edit'}}
            {{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Orders by Vendor</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-input label="Start Date" type="date" id="start-datetime" v-model="start"/>
                <base-input label="End Date" type="date" id="end-datetime" v-model="end"/>
                <base-button size="sm" type="neutral" v-on:click="formSubmit">Download Report</base-button>
                <small>Report will download as a CSV file.</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import config from '@/config'

  export default {
    name: 'reports',
    components: {
    },
    data() {
      return {
        start: '',
        end: ''
      }
    },
    computed: {
      formAction() {
        return 'event/' +
          (this.$store.state.formData.eventForm.id !== '' ?
            this.$store.state.formData.eventForm.id + '/update' : this.$route.params.parent + '/create')
      }
    },
    methods: {
      formSubmit() {
        window.open(config.serverURI + '/report/order-totals-by-vendor/?start=' + this.start + '&end=' + this.end + '&format=csv')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .event-image {
    display: block;
    max-width: 50%;
    margin: auto;
  }

  .alert-success {
    transition: .3s;
  }

  .loading-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .attachments {
    margin-top: 15px;

    .attachment {
      i {
        margin-right: 5px;
      }

      a {
        font-size: 0.9rem;
        color: #777777;
      }

      margin-bottom: 10px;
    }
  }
</style>
