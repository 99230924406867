import axios from 'axios'
import config from "../config";
import store from "./index";

export default {
  /*
   * Load API
   */

  LOAD_API({commit, dispatch}, payloadObjOrString) {
    let payload = {}
    if (typeof payloadObjOrString === 'string') {
      payload.name = payloadObjOrString
      payload.url = payloadObjOrString
    } else {
      payload = payloadObjOrString
    }

    let merge = payload.form === true

    if (payload.name === undefined && payload.url === undefined) {
      reject('Payload not supplied.')
    }
    return new Promise(function (resolve, reject) {
      let axiosConfig = {}
      axiosConfig.withCredentials = true

      axios.get(payload.url, axiosConfig)
        .then(response => {
          let data = response.data
          if (!isJSON(data)) {
            dispatch('VERIFY_SESSION')
            reject('Invalid response')
          }
          if (data.error) {
            dispatch('VERIFY_SESSION')
            reject(data.error)
          }
          if (data.type === 'error') {
            dispatch('VERIFY_SESSION')
            reject(data.message || 'Unknown error')
          }
          if (payload.id !== undefined) {
            if (payload.id === true && data.id !== undefined) {
              commit('SET_STATE', {name: payload.name, id: data.id, data: data, merge: merge})
            } else {
              commit('SET_STATE', {name: payload.name, id: payload.id, data: data, merge: merge})
            }
          } else if (payload.setstate === undefined || payload.setstate === true) {
            commit('SET_STATE', {name: payload.name, data: data, merge: merge})
          }
          resolve(data)
        })
        .catch(error => {
          dispatch('VERIFY_SESSION')
          console.log(error)
          reject('Request failed.')
        })
    })

  },
  /*
   * Post to API
   */

  POST_TO_API({state, dispatch}, event) {
    if (event.target.attributes.action.value && event.target.id && state.formData[event.target.id] !== undefined) {
      let action = event.target.attributes.action.value
      return new Promise(function (resolve, reject) {

        let formData = new FormData(event.target)
        for (let formField in state.formData[event.target.id]) {
          let fieldValue = state.formData[event.target.id][formField]

          // TODO: There is definitely a better way to do this
          if (fieldValue !== undefined && (
              (
                formField !== 'id' && formField !== 'type'
                && formField !== 'image'
                && (formField !== 'alt_image' || (formField === 'alt_image' && typeof fieldValue === 'object'))
                && formField !== 'og_image'
                && formField !== 'attachment'
                && formField !== 'taxonomy' && formField !== 'item_sold_out'
                && (formField !== 'parent' || typeof fieldValue !== 'object')
              ))
            || (Array.isArray(fieldValue) && fieldValue.length === 1 && fieldValue[0].indexOf('https://www.bayviewvillageshops.com') > -1)
            || (typeof fieldValue === 'string' && fieldValue.indexOf('https://www.bayviewvillageshops.com') > -1)
          ) {

            if (typeof fieldValue === 'object') {
              fieldValue = JSON.stringify(fieldValue)
            }

            /*if (formField === 'image' || formField === 'alt_image') {
              formData.delete(formField)
            }*/

            formData.append(formField, fieldValue)

            // Decrement by 1 = default
            if (formField === 'item_decrement' && state.formData[event.target.id][formField] === 1) {
              formData.delete('item_decrement')
            }
          }
        }
        axios.post(action, formData, {withCredentials: true})
          .then(response => {

            let data = response.data

            if (!isJSON(data)) {
              reject('Invalid response')
            }
            if (data.error) {
              reject(data.error)
            }
            if (data.type && data.type === 'error') {
              reject(data.message)
            }
            resolve(data)
          })
          .catch(error => {
            console.log(error)
            reject('Server appears to be offline')
          })
      })
    }
  },
  DELETE_ENTITY({commit, dispatch}, payload) {

    return new Promise(function (resolve, reject) {
      if (!payload.id || !payload.type) {
        reject('Id or Type not supplied.')
      }

      let axiosConfig = {}
      axiosConfig.withCredentials = true

      axios.get('/' + payload.type + '/' + payload.id + '/delete', axiosConfig)
        .then(response => {
          let data = response.data
          if (!isJSON(data)) {
            dispatch('VERIFY_SESSION')
            reject('Invalid response')
          }
          if (data.error) {
            dispatch('VERIFY_SESSION')
            reject(data.error)
          }
          if (data.type === 'error') {
            dispatch('VERIFY_SESSION')
            reject(data.message || 'Unknown error')
          }

          resolve(true)
        })
        .catch(error => {
          dispatch('VERIFY_SESSION')
          console.log(error)
          reject('Request failed.')
        })
    })
  },
  MOVE_ENTITY({commit, dispatch}, payload) {

    return new Promise(function (resolve, reject) {
      if (!payload.id || !payload.type) {
        reject('Id or Type not supplied.')
      }

      let formData = new FormData()
      formData.append('parent', '!E!' + payload.to)

      axios.post('/' + payload.type + '/' + payload.id + '/update', formData, {withCredentials: true})
        .then(response => {
          let data = response.data
          if (!isJSON(data)) {
            dispatch('VERIFY_SESSION')
            reject('Invalid response')
          }
          if (data.error) {
            dispatch('VERIFY_SESSION')
            reject(data.error)
          }
          if (data.type === 'error') {
            dispatch('VERIFY_SESSION')
            reject(data.message || 'Unknown error')
          }

          resolve(true)
        })
        .catch(error => {
          dispatch('VERIFY_SESSION')
          console.log(error)
          reject('Request failed.')
        })
    })
  },
  /*
   * Upload File
   */

  UPLOAD_FILE({state, dispatch}, file) {
    return new Promise(function (resolve, reject) {

      let formData = new FormData();
      formData.append('upload_file', file)

      axios.post('/media/drop-box/create', formData, {withCredentials: true})
        .then(response => {

          let data = response.data

          if (!isJSON(data)) {
            reject('Invalid response')
          }
          if (data.error) {
            reject(data.error)
          }
          if (data.type && data.type === 'error') {
            reject(data.message)
          }
          resolve(data)
        })
        .catch(error => {
          console.log(error)
          reject('Server appears to be offline')
        })
    })
  },
  UPDATE_SORT_ORDER(state, payload) {
    return new Promise(function (resolve, reject) {

      if (payload.new_sort_order === undefined || !Array.isArray(payload.new_sort_order) ||
        payload.parent === undefined || typeof payload.parent !== 'string')
        return false;
      let formData = new FormData()
      formData.append('new_sort_order', JSON.stringify(payload.new_sort_order))

      axios.post('/group/' + payload.parent + '/update', formData, {withCredentials: true})
        .then(response => {

          let data = response.data

          if (!isJSON(data)) {
            reject('Invalid response')
          }
          if (data.error) {
            reject(data.error)
          }
          if (data.type && data.type === 'error') {
            reject(data.message)
          }
          resolve(data)
        })
        .catch(error => {
          console.log(error)
          reject('Server appears to be offline')
        })
    })
  },
  SET_AUTOSAVE({state}, payload) {
    let formData = new FormData()
    formData.append('content', JSON.stringify(payload))
    if (payload.id === undefined)
      return false;
    return new Promise(function (resolve, reject) {
      axios.post('/autosave/' + payload.id + '/create', formData, {withCredentials: true})
        .then(response => {

          let data = response.data

          if (!isJSON(data)) {
            reject('Invalid response')
          }
          if (data.error) {
            reject(data.error)
          }
          if (data.type && data.type === 'error') {
            reject(data.message)
          }
          resolve(data)
        })
        .catch(error => {
          console.log(error)
          reject('Server appears to be offline')
        })
    })
  },
  GET_AUTOSAVE({state}, payload) {
    return new Promise(function (resolve, reject) {
      let axiosConfig = {}
      axiosConfig.withCredentials = true
      if (payload.id === undefined || payload.id.length === 0)
        return false;
      axios.get('/autosave/' + payload.id, axiosConfig)
        .then(response => {
          let data = response.data
          resolve(data)
        })
        .catch(error => {
          console.log(error)
          reject('Request failed.')
        })
    })
  },
  REMOVE_AUTOSAVE({state}, payload) {
    return new Promise(function (resolve, reject) {
      let axiosConfig = {}
      axiosConfig.withCredentials = true
      if (payload.id === undefined || payload.id.length === 0)
        return false;
      axios.get('/autosave/' + payload.id + '/delete', axiosConfig)
        .then(response => {
          let data = response.data
          if (!isJSON(data)) {
            reject('Invalid response')
          }
          if (data.error) {
            reject(data.error)
          }
          if (data.type === 'error') {
            reject(data.message || 'Unknown error')
          }
          resolve(data)
        })
        .catch(error => {
          dispatch('VERIFY_SESSION')
          console.log(error)
          reject('Request failed.')
        })
    })
  },
  LOG_USER_IN({state, commit, dispatch}, payload) {
    const formData = {username: payload.username, password: payload.password}

    let closure = this
    return new Promise(function (resolve, reject) {
      if (!formData.username || !formData.password)
        reject('Username or password not supplied')

      axios.post('loguserin', formData, {withCredentials: true}).then(response => {

        let data = response.data

        if (!isJSON(data)) {
          reject('Invalid response')
        }
        if (data.error) {
          reject(data.error)
        }
        if (data.type && data.type === 'error') {
          reject(data.message)
        }
        if (data.user) {
          commit('SET_USER', data.user)
          commit('SET_TOKEN', data.token)

          if (localStorage) {
            localStorage.setItem('user', JSON.stringify(data.user))
            localStorage.setItem('token', data.token)
          }

          dispatch('RESET_SESSION_HEARTBEAT')
          resolve(data)
        }
        reject('Error logging user in.')
      }).catch(error => {
        console.log(error)
        reject('Server appears to be offline')
      })
    })
  },
  VERIFY_SESSION({state, commit, dispatch}, heartbeatTrigger) {
    // If the user is still logged into the dashboard
    if (state.token) {
      if (!heartbeatTrigger)
        dispatch('RESET_SESSION_HEARTBEAT')

      // Check that they are still logged into the API
      axios.get('user?token=' + state.token, {withCredentials: true}).then(response => {
        // If there's a response but it's not a user, reset session
        if (response.status === 200 && response.data.type && response.data.type === 'user')
          return true
        else
          commit('RESET_SESSION')

      }).catch(function () {
        // If there's an error, reset session
        commit('RESET_SESSION')
      })
    } else
      dispatch('RESET_SESSION_HEARTBEAT', true)
    return false
  },
  LOAD_CONFIG({dispatch}) {
    dispatch('LOAD_API', 'config')
  },
  RESET_SESSION_HEARTBEAT({state, dispatch, commit}, cancel) {
    if (cancel === true && state.heartbeatTimer !== false) {
      clearInterval(state.heartbeatTimer)
      commit('RESET_STATE', 'heartbeatTimer')
    } else if (!cancel && state.heartbeatTimer === false) {
      state.heartbeatTimer = setInterval(function () {
        dispatch('VERIFY_SESSION', true)
      }, 300000)
    }
  },
  ALIAS_AVAILABLE({}, payload) {
    return axios.get('/status/alias?term=' + payload)
  },
  ENTITY_SEARCH({}, payload) {
    return axios.get('/' + payload.route + '/' + payload.parent + '/?search=' + payload.term)
  }
}

function isJSON(item) {
  item = typeof item !== 'string'
    ? JSON.stringify(item)
    : item

  try {
    item = JSON.parse(item)
  } catch (e) {
    return false
  }

  if (typeof item === 'object' && item !== null) {
    return true
  }
  return false
}
