<template>
  <div>
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Products</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" v-on:click="$router.push('/' + routeBase + '/product/new')">New
          </base-button>
          <!--<base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <Loader v-if="loading"/>
    <div class="container-fluid mt--6" v-else>
      <div class="row">
        <div class="col">
          <products-table :items="products" :parent="parent" :key="parent"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import ProductsTable from "@/views/CORE/ProductsTable";

export default {
  components: {
    ProductsTable,
    RouteBreadCrumb,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      loading: true,
      parent: false
    }
  },
  computed: {
    ...mapState({
      ecommerceProducts: state => state.products,
      retailerProducts: state => state.retailerProducts,
      archiveProducts: state => state.archiveProducts,
    }),
    routeBase() {
      return this.$route.path.split('/')[1]
    },
    routePath() {
      return this.$route.path.split('/')[2]
    },
    products() {
      if (this.routeBase === 'retailers')
        return this.retailerProducts

      if (this.routeBase === 'shop' && this.routePath === 'archive')
        return this.archiveProducts

      return this.ecommerceProducts
    }
  },
  methods: {
    initializeTable: function () {
      let closure = this
      if (this.routeBase === 'retailers') {
        closure.parent = 'retailer-items'
        if (this.retailerProducts.length === 0) {
          this.loading = true
          this.$store.dispatch('LOAD_API', {
            url: 'products/retailer-items',
            name: 'retailerProducts'
          }).then(function () {
            closure.loading = false
          })
        } else closure.loading = false
      } else if (this.routeBase === 'shop' && this.routePath === 'archive') {
        closure.parent = 'item-archive'
        if (this.archiveProducts.length === 0) {
          this.loading = true
          this.$store.dispatch('LOAD_API', {
            url: 'products/item-archive',
            name: 'archiveProducts'
          }).then(function () {
            closure.loading = false
          })
        } else closure.loading = false
      } else {
        closure.parent = 'items'
        if (this.ecommerceProducts.length === 0) {
          this.loading = true
          this.$store.dispatch('LOAD_API', 'products')
            .then(function () {
              closure.loading = false
            })
        } else closure.loading = false
      }
    }
  },
  created() {
    this.initializeTable()
  },
  watch: {
    '$route.path': function () {
      this.initializeTable()
    }
  }
};
</script>
