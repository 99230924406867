var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.config.config_domain.indexOf('forvest') === -1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Gastronomer',
          icon: 'fa fa-utensils text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Products', path: '/shop/products' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Vendors', path: '/shop/vendors' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Archive', path: '/shop/archive' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Reports', path: '/shop/reports' }}})],1):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{
          name: 'Retailers',
          icon: 'fa fa-store text-primary'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Retail Shops', path: '/retailers' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Products', path: '/retailers/products' }}})],1):_vm._e(),(_vm.config.config_domain.indexOf('forvest') > -1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Pages',
          icon: 'fa fa-columns text-primary',
        }}},[(_vm.config.config_domain.indexOf('forvest') > -1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Forvest CH',
          path: '/pages/forvestch'
        }}}):_vm._e(),(_vm.config.config_domain.indexOf('forvest') > -1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Forvest Global',
          path: '/pages/forvestglobal'
        }}}):_vm._e()],1):_vm._e(),(_vm.config.config_domain.indexOf('forvest') > -1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Lists',
          icon: 'fa fa-list text-primary'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Geneva Team', path: '/profiles/team-ch' }}})],1):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{
          name: 'Pages',
          icon: 'fa fa-columns text-primary',
          path: '/pages'
        }}}):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{
          name: 'Collections',
          icon: 'fa fa-object-group text-primary'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Main Page Slider', path: '/collection/main-slider' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Job Postings', path: '/collection/job-postings' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Media Room', path: '/collection/media-room' }}}),(['ianmorrison','superadmin'].indexOf(_vm.$store.state.user.alias) > -1)?_c('sidebar-item',{attrs:{"link":{ name: 'Lookbooks', path: '/collection/lookbooks' }}}):_vm._e()],1):_vm._e(),(_vm.config.config_domain === 'forvestglobalcom')?_c('sidebar-item',{attrs:{"link":{
          name: 'Community',
          icon: 'fa fa-newspaper text-primary',
          path: '/articles'
        }}}):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{
          name: 'Blog',
          icon: 'fa fa-newspaper text-primary'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Articles', path: '/articles' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Authors', path: '/profiles/blog-authors' }}})],1):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{
          name: 'Events',
          icon: 'fa fa-calendar-alt text-primary',
          path: '/calendar/events'
        }}}):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{
          name: 'Lists',
          icon: 'fa fa-list text-primary',
        }}},[(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{ name: 'New to BV', path: '/list/featured-retailers' }}}):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{ name: 'Featured Articles', path: '/list/featured-articles' }}}):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{ name: 'Currently Coveting', path: '/list/currently-coveting' }}}):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{ name: 'Hautelisted', path: '/list/hautelisted-list' }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Media',
          icon: 'fa fa-file-upload text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Public Files', path: '/media/public-files' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'All Files', path: '/media/drop-box' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Settings',
          icon: 'fa fa-cog text-primary',
        }}},[(_vm.config.config_domain === 'shopwillowbrookcom')?_c('sidebar-item',{attrs:{"link":{ name: 'Email Templates', path: '/settings/templates/email-templates' }}}):_vm._e(),(_vm.config.config_domain === 'bayviewvillageshopscom')?_c('sidebar-item',{attrs:{"link":{ name: 'Footer', path: '/settings/template-component/footer-content' }}}):_vm._e()],1),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"sidebar-menu-item nav-link",on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fa fa-power-off text-primary"}),_vm._v(" Log Out ")])])],1),_c('template',{slot:"links-after"})],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e(),_c('TimeoutModal',{attrs:{"confirmCallback":_vm.onStayLoggedIn,"timeoutClock":_vm.timeout.countdownTime},model:{value:(_vm.timeout.showTimeoutModal),callback:function ($$v) {_vm.$set(_vm.timeout, "showTimeoutModal", $$v)},expression:"timeout.showTimeoutModal"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }