<template>
  <div>
    <draggable
      handle=".handle"
      :value="contents"
      class="article-content__wrapper"
      ghost-class="ghost"
      v-on:end="handleDragEnd"
      :group="{ name: 'article-sections', pull: 'clone', put: false }"
    >
      <div v-for="(content, index) in contents" :key="index"
           :class="['card-row', 'my-3', {selected: content.taxonomy===undefined}]">
        <ContentSectionMenu class="card-row__content-selector"
                            :content="content"
                            :index="index"
                            :handleNewContentSection="handleNewContentSection"
                            :handleRemoveContentSection="handleRemoveContentSection"
                            :toggleTitle="toggleTitle"
                            :shiftSection="shiftSection"/>
        <i class="handle fa fa-grip-vertical"></i>
        <NewSectionSelector v-if="content.taxonomy===undefined"
                            :index="index"
                            :handleNewContentSection="handleNewContentSection"
                            :handleRemoveContentSection="handleRemoveContentSection"/>
        <div class="card-body" v-else>
          <div class="section-title" v-if="content.title !== undefined">
            <i class="fa fa-times float-right" v-on:click="toggleTitle(index, false)"></i>
            <base-input placeholder="Section title" v-model="content.title"/>
          </div>
          <div class="section-content page-content" v-if="content.taxonomy.id==='page-content'">
            <html-editor v-model="content.content"
                         :class="[{'empty-editor': content.content.replace(/(<([^>]+)>)/gi, '').length === 0}]"></html-editor>
          </div>
          <div class="section-content page-image-list" v-else-if="content.taxonomy.id==='page-image-list'">
            <ImagesGallery v-model="content.list_assignments" :toggleFileModal="toggleFileModal"
                           :parentIndex="index"/>
          </div>
          <div class="section-content page-item-list" v-else-if="content.taxonomy.id==='page-item-list'">
            <ProductsGallery v-model="content.list_assignments" :parentIndex="index"/>
          </div>
          <div class="section-content page-video-embed" v-else-if="content.taxonomy.id==='page-video-embed'">
            <VideoEmbed v-model="content.content"/>
          </div>
        </div>
      </div>
    </draggable>
    <div class="article-content__footer text-center my-3">
      <i class="fa fa-plus" v-on:click="handleNewContentSection('selector', contents.length)"></i>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import ContentSectionMenu from '@/components/CORE/ContentSectionMenu'
import NewSectionSelector from '@/components/CORE/ContentSections/NewSectionSelector'
import HtmlEditor from '@/components/Inputs/NewHtmlEditor'
import ImagesGallery from '@/components/CORE/ContentSections/ImagesGallery'
import ProductsGallery from '@/components/CORE/ContentSections/ProductsGallery'
import VideoEmbed from '@/components/CORE/ContentSections/VideoEmbed'

export default {
  name: 'ArticleContent',
  components: {
    draggable,
    ContentSectionMenu,
    NewSectionSelector,
    HtmlEditor,
    ImagesGallery,
    ProductsGallery,
    VideoEmbed
  },
  props: {
    contents: Array,
    toggleFileModal: Function
  },
  data() {
    return {
      sectionTemplates: {
        selector: {},
        text: {
          type: 'publication',
          id: 'new-t1',
          taxonomy: {
            id: 'page-content'
          },
          title: '',
          content: '',
          contents: []
        },
        images: {
          type: 'publication',
          id: 'new-i1',
          taxonomy: {
            id: 'page-image-list'
          },
          title: '',
          list_assignments: [
            {id: 'new-ila1'},
            {id: 'new-ila2'}
          ]
        },
        products: {
          type: 'publication',
          id: 'new-p1',
          taxonomy: {
            id: 'page-item-list'
          },
          title: '',
          list_assignments: [
            {id: 'new-pla1'},
            {id: 'new-pla2'}
          ]
        },
        video: {
          type: 'publication',
          id: 'new-v1',
          taxonomy: {
            id: 'page-video-embed'
          },
          title: '',
          content: ''
        }
      }
    }
  },
  methods: {
    handleNewContentSection(type, index) {
      const newSection = JSON.parse(JSON.stringify(this.sectionTemplates[type]))
      newSection.id = 'new-' + Math.random().toString(36).replace(/[^A-Za-z1-9]+/g, '').substr(0, 5)

      this.$store.commit('ARRAY_SPLICE', {
        name: 'pageForm',
        field: 'contents',
        index: index,
        deleteCount: type === 'selector' ? 0 : 1,
        newItem: newSection
      })
    },
    handleRemoveContentSection(index) {
      //this.$store.state.formData.pageForm.contents.splice(index, 1)
      this.$store.commit('ARRAY_SPLICE', {
        name: 'pageForm',
        field: 'contents',
        index: index,
        deleteCount: 1
      })
    },
    handleDragEnd(data) {
      console.log(data)
    },
    shiftSection(index, change) {
      this.$store.commit('ARRAY_MOVE', {
        name: 'pageForm',
        field: 'contents',
        index: index,
        change: change
      })
    },
    toggleTitle(index, show) {
      if (show && this.$store.state.formData.pageForm.contents[index].title === undefined)
        this.$store.state.formData.pageForm.contents[index].title = ''
      else {
        this.$store.state.formData.pageForm.contents[index].title = undefined
      }
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep .ql-container {
  position: relative;

  .ql-editor a {
    color: #E51B80;
  }
}

.article-content {
  &__wrapper {
    margin-bottom: 15px;
    ::v-deep .ghost {
      .card-row__content-selector {
        visibility: hidden;
      }
      &::after {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(150, 200, 255, 0.5);
      }
    }
  }

  &__footer {
    .fa-plus {
      cursor: pointer;
    }
  }
}

.card-row {
  position: relative;
  padding: 0;
  background: #FFFFFF;

  &__content-selector {
    visibility: hidden;
    padding-left: 25px;
  }

  .handle {
    visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    top: -30px;
    height: 30px;
    left: 7px;
    font-size: 15px;
    color: #525f7f;
  }

  &:hover, &.selected {
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: calc(100% + 40px);
      pointer-events: none;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 10;
    }

    .card-row__content-selector,
    .handle {
      visibility: visible;
    }
  }

  .card-body {
    padding: 0 1.5rem;

    .section-title {
      margin: 1rem 0 0.5rem;

      ::v-deep {
        .form-group {
          margin-bottom: 0;
        }

        input {
          height: 2rem;
          padding: 0.5rem 0.75rem;
          transition: 0s;
          width: calc(100% - 15px);
        }

        input:not(:focus) {
          border: 0;
          padding: 0;
          font-size: 1.25rem;
          font-weight: 600;
          height: auto;
        }

        input:focus {
          margin: -1px 0;
        }
      }
    }

    ::v-deep p {
      margin: 1rem 0;
    }
  }

  .empty-editor {
    border: 1px #CCCCCC dashed;
  }
}
</style>
