<template>
  <div class="card">
    <div class="border-0 card-header">
      <h3 class="mb-0">All Pages</h3>
    </div>
    <el-table class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="items">
      <el-table-column :label="this.publicationType === 'article' ? 'Article' : 'Page'"
                       min-width="310px"
                       prop="name"
                       sortable>
        <template v-slot="{row}">
          <div class="media align-items-center">
<!--            <a :href="'#/page/' + row.id" class="avatar mr-3">
              <img :src="row.image"/>
            </a>-->
            <div class="media-body">
              <a :href="'#/' + publicationType + '/' + row.id">
                <span class="font-weight-600 name mb-0 text-sm">{{row.title}}</span>
              </a>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Categories" v-if="publicationType === 'article'" min-width="250px" sortable>
        <template v-slot="{row}">
          <template v-for="(taxonomy, index) in row.taxonomies">
            {{taxonomy.title}}<template v-if="(index + 1) < row.taxonomies.length">, </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Publish Date" v-if="publicationType === 'article'" min-width="250px" sortable>
        <template v-slot="{row}">
          {{row.publish_date}}
        </template>
      </el-table-column>

      <el-table-column min-width="180px">
        <template v-slot="{row}">
          <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
              <a class="dropdown-item" :href="'#/' + publicationType + '/'+row.id">Edit Page</a>
              <a v-if="config.config_domain == 'forvestglobalcom'" class="dropdown-item" target="_blank" :href="'https://www.forvest.ch/resource/'+row.alias">View Live</a>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <!--<base-pagination v-model="currentPage" :total="50"></base-pagination>-->
    </div>
  </div>
</template>
<script>
  import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import {mapState} from "vuex";

  export default {
    name: 'pages-table',
    props: {
      items: [Array, Object],
      default: []
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    computed: {
      ...mapState({
        config: state => state.config,
      })
    },
    data() {
      return {
        currentPage: 1,
        publicationType: 'article'
      };
    },
    created() {
      this.publicationType = this.$route.path.split('/')[1].slice(0, -1);
    },
    updated() {
      this.publicationType = this.$route.path.split('/')[1].slice(0, -1);
    }
  }
</script>
