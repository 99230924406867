<template>
  <form v-on:submit.prevent="formSubmit" :action="formAction" id="userForm" enctype="multipart/form-data">
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.params.id === 'new' ? 'Add' : 'Edit'}}
            {{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" v-on:click="$router.push('/user/new')">New</base-button>
          <base-button size="sm" type="neutral" v-on:click="$router.go(-1)">Back</base-button>
          <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
        </div>
      </div>
      <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
        <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{errorMessage}}</div>
        <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
      </div>
    </base-header>
    <div class="container-fluid mt--6 loading-container" v-if="!showForm">
      <div class="row">
        <div class="col-12">
          Loading...
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" v-else>
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Details</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-input label="First Name" placeholder="User first name" v-model="firstname"/>
                <base-input label="Last Name" placeholder="User last name" v-model="lastname"/>
                <base-input label="Username" placeholder="Username" v-model="alias"/>
                <base-input label="Email Address" type="email" placeholder="Email address" v-model="email"/>
                <base-input label="Password" type="password" v-model="password"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Image</h3>
              </div>
              <div class="card-body">
                <img class="user-image mb-4" v-if="$store.state.formData.userForm.image"
                     :src="$store.state.formData.userForm.image"/>
                <file-input name="image"></file-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import {mapFields} from 'vuex-map-fields'
  import {mapState} from "vuex";
  import FileInput from '@/components/Inputs/FileInput'

  export default {
    name: 'user',
    components: {
      FileInput
    },
    data() {
      return {
        submitEnabled: true,
        errorMessage: false,
        successMessage: false,
        showForm: false
      }
    },
    computed: {
      ...mapFields([
        'formData.userForm.firstname',
        'formData.userForm.lastname',
        'formData.userForm.alias',
        'formData.userForm.email',
        'formData.userForm.password'
      ]),
      ...mapState({
        products: state => state.products
      }),
      formAction() {
        return 'user/' +
          (this.$store.state.formData.userForm.id !== '' ?
            this.$store.state.formData.userForm.id + '/update' : 'users/create')
      }
    },
    methods: {
      formSubmit(event) {
        this.submitEnabled = false
        let closure = this
        this.$store.dispatch('POST_TO_API', event).then(function (message) {
          closure.submitEnabled = true
          if (event.target.attributes.action.value.split('/')[2] === 'update') {
            closure.showFormMessage(true)
            closure.initializeForm()
          } else {
            closure.$store.commit('RESET_FORM_STATE', 'userForm')
            closure.$router.push('/user/' + message.id)
          }
        }).catch(function (message) {
          closure.showFormMessage(message)
          closure.submitEnabled = true
        })
      },
      showFormMessage(message) {
        if (message === true) {
          this.errorMessage = false
          this.successMessage = true
          let closure = this
          setTimeout(function () {
            closure.successMessage = false
          }, 2000)
        } else {
          this.errorMessage = message
        }
      },
      initializeForm() {
        if (this.products.length === 0) {
          //this.$store.dispatch('LOAD_API', 'products');
        }
        if (this.$route.params.id === 'new') {
          this.$store.commit('RESET_FORM_STATE', 'userForm')
          this.showForm = true
        } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
          let closure = this
          this.$store.dispatch('LOAD_API', {
            url: 'user/' + this.$route.params.id,
            name: 'formData',
            id: 'userForm',
            form: true
          }).then(function () {
            closure.showForm = true
          });
        } else {
          this.showFormMessage('user id not supplied. Please go back to users and try again.')
        }
      }
    },
    created() {
      this.initializeForm()
    },
    watch: {
      '$route.params.id': function (id) {
        this.initializeForm(id)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .user-image {
    display: block;
    max-width: 60%;
    margin: auto;
  }

  .alert-success {
    transition: .3s;
  }

  .loading-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
