<template>
  <div>
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Collection</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" v-on:click="$router.push('/collection/collection-item/new/' + $route.params.id)">
            New
          </base-button>
          <!--<base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6" v-if="collections[$route.params.id] && collections[$route.params.id].length > 0">
      <div class="row">
        <div class="col">
          <collection-table :items="collections[$route.params.id]" :parent="$route.params.id" />
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>
<script>
  import {mapState} from "vuex";
  import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import CollectionTable from "@/views/CORE/CollectionTable";

  export default {
    components: {
      CollectionTable,
      RouteBreadCrumb,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {};
    },
    computed: {
      ...mapState({
        collections: state => state.collections
      })
    },
    methods: {
      loadCollection(id) {
        if(!this.collections[id] || this.collections[id].length === 0)
          this.$store.dispatch('LOAD_API', {name: 'collections', id: id, url: 'collection/' + id})
      }
    },
    watch: {
      '$route.params.id': function (id) {
        this.loadCollection(id)
      }
    },
    created() {
      this.loadCollection(this.$route.params.id)
    }
  };
</script>
