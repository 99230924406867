/**
 * Facebook Graph API
 */

import axios from 'axios'

export default {
  // Initiate Facebook SDK script (https://developers.facebook.com/docs/javascript/quickstart)
  init() {
    this.load();
    return new Promise(resolve => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: 1038762816629617,
          cookie: true,
          xfbml: true,
          version: 'v10.0'
        })

        window.FB.login(function (response) {
          if (response.authResponse) {
            //window.FB.api('/me', function (response) {
            //  console.log(response);
            //});
            if (response.authResponse.accessToken) {
              axios.get('/graphapi?token=' + response.authResponse.accessToken).then(function (data) {
                resolve()
              })

            }
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        }, {scope: 'pages_show_lists,instagram_basic'});
      }
    })
  },
  load() {
    // Load Facebook SDK script (https://developers.facebook.com/docs/javascript/quickstart)
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      //js.src = "https://connect.facebook.net/en_US/sdk/debug.js";
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    return true;
  }
}
