var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"border-0 card-header"},[(_vm.newOrder.length > 0)?_c('div',{staticClass:"float-right"},[_c('span',{staticClass:"text-danger"},[_vm._v("Sort order changed.")]),_c('base-button',{staticClass:"ml-3 py-0 px-3",attrs:{"type":"neutral"},on:{"click":_vm.saveSortOrder}},[_vm._v("Save Sort Order")]),_c('base-button',{staticClass:"ml-1 py-0 px-3",attrs:{"type":"white"},on:{"click":_vm.resetSortOrder}},[_vm._v("Restore")])],1):_vm._e(),_c('h3',{staticClass:"mb-0"},[_vm._v("Collection")])]),_c('el-table-draggable',{attrs:{"handle":".handle","direction":"vertical"},on:{"drop":_vm.handleDrop}},[_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.itemsCopy}},[_c('el-table-column',{attrs:{"label":"Collection Item","min-width":"310px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('i',{staticClass:"handle mr-3 fa fa-grip-vertical"}),(row.image)?_c('a',{staticClass:"avatar mr-3",attrs:{"href":'#/collection/collection-item/' + row.id + '/' + _vm.$route.params.id}},[_c('img',{attrs:{"src":row.image}})]):_vm._e(),_c('div',{staticClass:"media-body"},[_c('a',{attrs:{"href":'#/collection/collection-item/' + row.id + '/' + _vm.$route.params.id}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.title))])])])])]}}])}),(_vm.$route.params.id === 'job-postings')?_c('el-table-column',{attrs:{"label":"Retailer","min-width":"310px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.vendors)?_c('div',[_vm._v(" "+_vm._s(row.vendors[0].title)+" ")]):_vm._e()]}}],null,false,157184432)}):_vm._e(),(_vm.$route.params.id === 'media-room')?_c('el-table-column',{attrs:{"label":"Publisher","min-width":"200px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.publishers)?_c('div',[_vm._v(" "+_vm._s(row.publishers[0].title)+" ")]):_vm._e()]}}],null,false,3395330512)}):_vm._e(),(_vm.$route.params.id === 'media-room')?_c('el-table-column',{attrs:{"label":"Publish Date","min-width":"200px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.publish))+" ")]}}],null,false,1216214539)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":'#/collection/collection-item/'+row.id}},[_vm._v("Edit Collection Item")])])],1)]}}])})],1)],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-end"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }