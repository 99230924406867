var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.items}},[_c('el-table-column',{attrs:{"label":"Item","min-width":"310px","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.title))])])])]}}])}),_c('el-table-column',{attrs:{"label":"Type","min-width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.taxonomies && row.taxonomies.length > 0)?[_vm._v(_vm._s(row.taxonomies[0].title))]:(row.taxonomy)?[_vm._v(_vm._s(row.taxonomy.title))]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.removeItem(row.id)}}},[_vm._v("Remove Item from List")])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Move","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"move-arrow fa fa-arrow-down",on:{"click":function($event){return _vm.moveItem(row.id, 1)}}}),_c('i',{staticClass:"move-arrow fa fa-arrow-up",on:{"click":function($event){return _vm.moveItem(row.id, -1)}}})]}}])})],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-end"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("List Items")])])}]

export { render, staticRenderFns }