var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"border-0 card-header"},[(_vm.newOrder.length > 0)?_c('div',{staticClass:"float-right"},[_c('span',{staticClass:"text-danger"},[_vm._v("Sort order changed.")]),_c('base-button',{staticClass:"ml-3 py-0 px-3",attrs:{"type":"neutral"},on:{"click":_vm.saveSortOrder}},[_vm._v("Save Sort Order")]),_c('base-button',{staticClass:"ml-1 py-0 px-3",attrs:{"type":"white"},on:{"click":_vm.resetSortOrder}},[_vm._v("Restore")])],1):_vm._e(),_c('h3',{staticClass:"mb-0"},[_vm._v("All Products")])]),_c('el-table-draggable',{attrs:{"handle":".handle","direction":"vertical"},on:{"drop":_vm.handleDrop}},[_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.itemsCopy}},[_c('el-table-column',{attrs:{"label":"Product","min-width":"310px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[(_vm.parent==='items')?_c('i',{staticClass:"handle mr-3 fa fa-grip-vertical"}):_vm._e(),_c('a',{staticClass:"avatar mr-3",attrs:{"href":'#/'+ _vm.routeBase +'/product/' + row.id}},[_c('img',{attrs:{"src":row.image}})]),_c('div',{staticClass:"media-body"},[_c('a',{attrs:{"href":'#/'+ _vm.routeBase +'/product/' + row.id}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[(row.status === 2)?_c('strong',[_vm._v("DRAFT - ")]):_vm._e(),_vm._v(_vm._s(row.title))])])])])]}}])}),_c('el-table-column',{attrs:{"label":"Vendors","min-width":"200px","prop":"vendor","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.vendors),function(vendor,index){return (row.vendors)?_c('a',{key:index,staticClass:"vendor-link",attrs:{"href":'#/'+ _vm.routeBase +'/vendor/' + vendor.id}},[_c('span',{staticClass:"status"},[_vm._v(_vm._s(vendor.title))])]):_vm._e()})}}])}),(_vm.routeBase !== 'retailers')?_c('el-table-column',{attrs:{"label":"Price","prop":"item_price","min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item_price))+" ")]}}],null,false,2957370726)}):_vm._e(),(_vm.routeBase !== 'retailers')?_c('el-table-column',{attrs:{"label":"Tax","prop":"tax","min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item_tax + (row.item_tax2 || 0)))+" ")]}}],null,false,1608543341)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":'#/'+ _vm.routeBase +'/product/' + row.id}},[_vm._v("Edit Product")]),(row.vendor)?_c('a',{staticClass:"dropdown-item",attrs:{"href":'#/'+ _vm.routeBase +'/vendor/'+row.vendor.id}},[_vm._v("View Vendor")]):_vm._e()])],1)]}}])})],1)],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-end"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }