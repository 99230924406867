<template>
  <div class="delete-object">
    <base-button size="sm" type="danger" class="section-content__remove"
                 v-on:click="showDeleteModal = true">Delete Item
    </base-button>
    <DeleteItemModal v-model="showDeleteModal"
                     :entity="entity"
                     :confirmCallback="deleteProduct"
                     :successRoute="successRoute"/>
  </div>
</template>
<script>
import DeleteItemModal from '@/components/CORE/Modals/DeleteItemModal'

export default {
  name: 'DeleteControls',
  props: {
    entity: Object,
    successRoute: String
  },
  components: {DeleteItemModal},
  data() {
    return {
      showDeleteModal: false
    }
  },
  methods: {
    deleteProduct(id) {
      return this.$store.dispatch('DELETE_ENTITY', {type: 'item', id: id})
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
