<template>
  <div>
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Pages</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" v-on:click="$router.push('/' + publicationType + '/new/' + pageGroup)">New</base-button>
          <!--<base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6" v-if="pages[pageGroup]">
      <div class="row">
        <div class="col">
          <pages-table :items="pages[pageGroup]"/>
        </div>
      </div>
    </div>
   <Loader v-else />
  </div>
</template>
<script>
  import {mapState} from "vuex";
  import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import PagesTable from "@/views/CORE/PagesTable";

  export default {
    components: {
      PagesTable,
      RouteBreadCrumb,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        pageGroup: this.$route.params.id,
        publicationType: 'article',
      };
    },
    computed: {
      ...mapState(['pages'])
    },
    methods: {
      initializeList(alias) {
        if(alias === undefined) {
          if (this.publicationType === 'article')
            alias = 'publications'

          else if (this.publicationType === 'page')
            alias = 'pages'
        }

        const closure = this
        if(this.pages[alias] === undefined || this.pages[alias].length === 0)
          this.$store.dispatch('LOAD_API', {
            name: 'pages',
            id: alias,
            url: 'pages/' + alias + (alias === 'pages' ? '?sort=title' : '')}
          ).then(function() {
            closure.pageGroup = alias;
          });
        else
          closure.pageGroup = alias;
      }
    },
    created() {
      this.publicationType = this.$route.path.split('/')[1].slice(0,-1);

     this.initializeList(this.pageGroup)
    },
    watch: {
      '$route.params.id': function (alias) {
        this.initializeList(alias);
      },
      '$route.path': function () {
        this.publicationType = this.$route.path.split('/')[1].slice(0,-1);
        this.initializeList();
      }
    }
  };
</script>
