import Vue from 'vue'
import template from '@/config/template'
import {updateField} from 'vuex-map-fields'
import getDefaultState from './state'
import router from '@/routes/router'
import arrayMove from "array-move";

export default {
  updateField,
  SET_USER(state, user) {
    state.user = user
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_STATE(state, payload) {
    if (payload.name !== undefined && payload.data !== undefined) {

      if (payload.id !== undefined) {
        if (!state[payload.name] || state[payload.name] === undefined) { // If the state has not been instantiated
          Vue.set(state, payload.name, {}) // we instantiate the state.
        }
        Vue.set(state[payload.name], payload.id, payload.merge === true ? {
          ...getDefaultState()[payload.name][payload.id],
          ...payload.data
        } : payload.data)
      } else {
        Vue.set(state, payload.name, payload.merge === true ? {
          ...getDefaultState()[payload.name],
          ...payload.data
        } : payload.data)
      }
    }
  },
  SET_FORM_STATE(state, payload) {
    if (payload.name !== undefined && payload.data !== undefined) {
      if (payload.field !== undefined) {
        Vue.set(state.formData[payload.name], payload.field,payload.merge === true ? {
          ...getDefaultState()[payload.name][payload.field],
          ...payload.data
        } : payload.data)
      } else {
        Vue.set(state.formData, payload.name, payload.merge === true ? {
          ...getDefaultState()[payload.name],
          ...payload.data
        } : payload.data)
      }
    }
  },
  RESET_STATE(state, stateObject) {
    if (stateObject === true) {
      Object.assign(state, getDefaultState())
    } else {
      Object.assign(state[stateObject], getDefaultState()[stateObject])
    }
  },
  RESET_FORM_STATE(state, formName) {
    Object.assign(state.formData[formName], getDefaultState().formData[formName])
  },
  ARRAY_SPLICE(state, payload) {
    if(payload.name === undefined || payload.field === undefined || payload.index === undefined || payload.deleteCount === undefined)
      return false

    if(payload.newItem !== undefined)
      state.formData[payload.name][payload.field].splice(payload.index, payload.deleteCount, payload.newItem)
    else
      state.formData[payload.name][payload.field].splice(payload.index, payload.deleteCount)
    return true
  },
  ARRAY_MOVE(state, payload) {
    const arrayMove = require('array-move');
    if(payload.name === undefined || payload.field === undefined || payload.index === undefined || payload.change === undefined)
      return false
    const destination = payload.index + payload.change
    if (destination < 0 || destination > (state.formData[payload.name][payload.field].length - 1))
      return false
    const newArray = arrayMove(state.formData[payload.name][payload.field], payload.index, destination)
    Vue.set(state.formData[payload.name],payload.field, newArray)
    return true
  },
  ADD_TO_CART(state, payload) {
    if (payload.value === '') {
      delete state.cart.items[payload.id]
    } else if (payload.increment !== undefined &&
      payload.increment === true && state.cart.items[payload.id] !== undefined) {
      state.cart.items[payload.id] = state.cart.items[payload.id] + payload.value
    } else {
      state.cart.items[payload.id] = payload.value
    }
    this.commit('UPDATE_CART')
  },
  SET_FILTER(state, payload) {
    if (payload.name !== undefined && payload.value !== undefined) {
      let stateName = 'shopFilters'
      if (payload.filter !== undefined) {
        stateName = payload.filter
      }
      if (Array.isArray(state[stateName][payload.name])) {
        let index = state[stateName][payload.name].indexOf(payload.value)
        if (index < 0) {
          state[stateName][payload.name].push(payload.value)
        } else if (payload.toggle === true) {
          state[stateName][payload.name].splice(index, 1)
        }
      } else {
        if (state[stateName][payload.name] !== payload.value) {
          state[stateName][payload.name] = payload.value
        } else {
          state[stateName][payload.name] = false
        }
      }
    }
  },
  RESET_FILTERS(state, payload) {
    let stateName = 'shopFilters'
    if (payload && payload.filter !== undefined) {
      stateName = payload.filter
    }
    this.commit('RESET_STATE', stateName)
  },
  RESET_SESSION() {

    // Cancel heartbeat
    this.dispatch('RESET_SESSION_HEARTBEAT', true)

    // Empty state user
    this.commit('SET_USER', null)
    this.commit('SET_TOKEN', null)

    // Clear local storage
    if (localStorage) {
      localStorage.setItem('user', null)
      localStorage.setItem('token', null)
    }

    // Reset state
    // TODO: Commit changed states to DB before clearing for recovery later
    this.commit('RESET_STATE', true) // true = reset entire state to default

    // Reload config into reset state
    this.dispatch('LOAD_CONFIG')

    // Redirect to login screen
    router.push('/login?loggedout=true')

  },
  FAVOURITE_ITEM(state, payload) {
    if (payload.value === '') {
      delete state.user_favourites[payload.id]
    } else if (payload.increment !== undefined &&
      payload.increment === true && state.user_favourites[payload.id] !== undefined) {
      state.user_favourites[payload.id] = state.user_favourites[payload.id] + payload.value
    } else {
      state.user_favourites[payload.id] = payload.value
    }
  },
  UPDATE_CART(state) {
    let computed = {}
    let itemsQuantity = 0
    let productsQuantity = 0
    let subtotal = 0

    for (let i = 0; i < state.products.length; i++) {
      let product = state.products[i]
      if (state.cart.items[product.id] !== undefined) {
        productsQuantity++
        let pieces = parseFloat(state.cart.items[product.id])
        itemsQuantity += pieces
        let price = product.item_price
        subtotal += price * pieces
      }
    }

    computed.itemsTotal = RoundCurrency(subtotal)
    computed.discountPercent = DiscountRate(state, itemsQuantity)
    computed.discountTotal = RoundCurrency(computed.itemsTotal * (computed.discountPercent / 100))
    computed.subtotal = RoundCurrency(computed.itemsTotal - computed.discountTotal)
    computed.taxTotal = RoundCurrency(computed.subtotal * (template.storeSettings.taxes[0].percent / 100))
    computed.itemsQuantity = itemsQuantity
    computed.productsQuantity = productsQuantity
    computed.totalValue = computed.subtotal + computed.taxTotal

    state.cart.totals = computed
  },
  TOGGLE_ORDER_PREVIEW(state, active = null) {
    if (active !== null) {
      state.state.cart.totals.preview = active
    } else {
      state.state.cart.totals.preview = !state.state.cart.totals.preview
    }
  },
  RESET_ORDER_FORM() {
    this.commit('RESET_STATE', 'activeOrder')
  }
}

function RoundCurrency(value) {
  return parseFloat(Math.round(value * 100) / 100)
}

/* function findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i]
        }
    }
    return null
} */

function DiscountRate(state, quantity) {
  let DiscountRate = 0
  /*
      if (state.activeOrder.coupon !== false && state.activeOrder.coupon.type === 'discount' &&
          state.activeOrder.coupon.discount && state.activeOrder.coupon.threshold < quantity + 1) {
          DiscountRate = state.activeOrder.coupon.discount
      }
  */
  if (state.discounts && state.discounts.children !== undefined) {
    for (let i = 0; i < state.discounts.children.length; i++) {
      let discount = state.discounts.children[i]
      if (discount.alias === undefined && discount.threshold < quantity + 1 && discount.discount > DiscountRate) {
        DiscountRate = discount.discount
      }
    }
  }
  return DiscountRate
}
