<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item v-if="config.config_domain.indexOf('forvest') === -1"
                      :link="{
            name: 'Gastronomer',
            icon: 'fa fa-utensils text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Products', path: '/shop/products' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Vendors', path: '/shop/vendors' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Archive', path: '/shop/archive' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Reports', path: '/shop/reports' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                      :link="{
            name: 'Retailers',
            icon: 'fa fa-store text-primary'
          }"
        >
          <sidebar-item
            :link="{ name: 'Retail Shops', path: '/retailers' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Products', path: '/retailers/products' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="config.config_domain.indexOf('forvest') > -1"
                      :link="{
            name: 'Pages',
            icon: 'fa fa-columns text-primary',
          }"
        >
          <sidebar-item v-if="config.config_domain.indexOf('forvest') > -1"
                        :link="{
            name: 'Forvest CH',
            path: '/pages/forvestch'
          }"
          ></sidebar-item>
          <sidebar-item v-if="config.config_domain.indexOf('forvest') > -1"
                        :link="{
            name: 'Forvest Global',
            path: '/pages/forvestglobal'
          }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="config.config_domain.indexOf('forvest') > -1"
                      :link="{
            name: 'Lists',
            icon: 'fa fa-list text-primary'
          }"
        >
          <sidebar-item
            :link="{ name: 'Geneva Team', path: '/profiles/team-ch' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                      :link="{
            name: 'Pages',
            icon: 'fa fa-columns text-primary',
            path: '/pages'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Collections',
            icon: 'fa fa-object-group text-primary'
          }"
          v-if="config.config_domain === 'bayviewvillageshopscom'"
        >
          <sidebar-item
            :link="{ name: 'Main Page Slider', path: '/collection/main-slider' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Job Postings', path: '/collection/job-postings' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Media Room', path: '/collection/media-room' }"
          ></sidebar-item>
          <sidebar-item v-if="['ianmorrison','superadmin'].indexOf($store.state.user.alias) > -1"
                        :link="{ name: 'Lookbooks', path: '/collection/lookbooks' }"
          ></sidebar-item>

        </sidebar-item>
        <sidebar-item v-if="config.config_domain === 'forvestglobalcom'"
                      :link="{
            name: 'Community',
            icon: 'fa fa-newspaper text-primary',
            path: '/articles'
          }"
        ></sidebar-item>
        <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                      :link="{
            name: 'Blog',
            icon: 'fa fa-newspaper text-primary'
          }"
        >
          <sidebar-item
            :link="{ name: 'Articles', path: '/articles' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Authors', path: '/profiles/blog-authors' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                      :link="{
            name: 'Events',
            icon: 'fa fa-calendar-alt text-primary',
            path: '/calendar/events'
          }"
        >
        </sidebar-item>
        <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                      :link="{
            name: 'Lists',
            icon: 'fa fa-list text-primary',
          }"
        >
          <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                        :link="{ name: 'New to BV', path: '/list/featured-retailers' }"
          ></sidebar-item>
          <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                        :link="{ name: 'Featured Articles', path: '/list/featured-articles' }"
          ></sidebar-item>
          <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                        :link="{ name: 'Currently Coveting', path: '/list/currently-coveting' }"
          ></sidebar-item>
          <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                        :link="{ name: 'Hautelisted', path: '/list/hautelisted-list' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Media',
            icon: 'fa fa-file-upload text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Public Files', path: '/media/public-files' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'All Files', path: '/media/drop-box' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Settings',
            icon: 'fa fa-cog text-primary',
          }"
        >
          <sidebar-item v-if="config.config_domain === 'shopwillowbrookcom'"
                        :link="{ name: 'Email Templates', path: '/settings/templates/email-templates' }"
          ></sidebar-item>
          <!--          <sidebar-item v-if="['ianmorrison','superadmin'].indexOf($store.state.user.alias) > -1"
                      :link="{ name: 'Users', path: '/settings/users' }"
                    ></sidebar-item>-->
          <sidebar-item v-if="config.config_domain === 'bayviewvillageshopscom'"
                        :link="{ name: 'Footer', path: '/settings/template-component/footer-content' }"
          ></sidebar-item>
        </sidebar-item>
        <li class="nav-item">
          <a class="sidebar-menu-item nav-link" v-on:click="logout()">
            <i class="fa fa-power-off text-primary"></i>
            Log Out
          </a>
        </li>
        <!--<sidebar-item :link="{
                  name: 'Examples',
                  icon: 'ni ni-ungroup text-orange'
                  }">
          <sidebar-item :link="{ name: 'Pricing', path: '/pricing' }"/>
          <sidebar-item :link="{ name: 'Login', path: '/login' }"/>
          <sidebar-item :link="{ name: 'Register', path: '/register' }"/>
          <sidebar-item :link="{ name: 'Lock', path: '/lock' }"/>
          <sidebar-item :link="{ name: 'Timeline', path: '/pages/timeline' }"/>
          <sidebar-item :link="{ name: 'Profile', path: '/pages/user' }"/>


        </sidebar-item>

        <sidebar-item :link="{
                  name: 'Components',
                  icon: 'ni ni-ui-04 text-info'
                }">
          <sidebar-item :link="{ name: 'Buttons', path: '/components/buttons' }"/>
          <sidebar-item :link="{ name: 'Cards', path: '/components/cards' }"/>
          <sidebar-item :link="{ name: 'Grid', path: '/components/grid-system' }"/>
          <sidebar-item :link="{ name: 'Notifications', path: '/components/notifications' }"/>
          <sidebar-item :link="{ name: 'Icons', path: '/components/icons' }"/>
          <sidebar-item :link="{ name: 'Typography', path: '/components/typography' }"/>

          <sidebar-item :link="{ name: 'Multi Level' }">
            <sidebar-item :link="{ name: 'Third level menu', path:'#!' }"/>
            <sidebar-item :link="{ name: 'Just another link', path:'#a' }"/>
            <sidebar-item :link="{ name: 'One last link', path:'#b' }"/>
          </sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{
                        name: 'Forms',
                        icon: 'ni ni-single-copy-04 text-pink'
                      }">
          <sidebar-item :link="{ name: 'Elements', path: '/forms/elements' }"/>
          <sidebar-item :link="{ name: 'Components', path: '/forms/components' }"/>
          <sidebar-item :link="{ name: 'Validation', path: '/forms/validation' }"/>
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Tables',
                        icon: 'ni ni-align-left-2 text-default'
                      }">
          <sidebar-item :link="{ name: 'Tables', path: '/tables/regular' }"/>
          <sidebar-item :link="{ name: 'Sortable', path: '/tables/sortable' }"/>
          <sidebar-item :link="{ name: 'Paginated Tables', path: '/tables/paginated' }"/>
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Maps',
                        icon: 'ni ni-map-big text-primary'
                      }">
          <sidebar-item :link="{ name: 'Google', path: '/maps/google' }"/>
          <sidebar-item :link="{ name: 'Vector', path: '/maps/vector' }"/>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Widgets',
            icon: 'ni ni-archive-2 text-green',
            path: '/widgets'
          }">
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Charts',
            icon: 'ni ni-chart-pie-35 text-info',
            path: '/charts'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Calendar',
            icon: 'ni ni-calendar-grid-58 text-red',
            path: '/calendar'
          }">
        </sidebar-item>-->
      </template>

      <template slot="links-after">
        <!--  <hr class="my-3">
          <h6 class="navbar-heading p-0 text-muted">Documentation</h6>

          <ul class="navbar-nav mb-md-3">
            <li class="nav-item">
              <a class="nav-link" href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"
                 target="_blank">
                <i class="ni ni-spaceship"></i>
                <span class="nav-link-text">Getting started</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html"
                 target="_blank">
                <i class="ni ni-palette"></i>
                <span class="nav-link-text">Foundation</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html"
                 target="_blank">
                <i class="ni ni-ui-04"></i>
                <span class="nav-link-text">Components</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/plugin_datepicker.html"
                 target="_blank">
                <i class="ni ni-chart-pie-35"></i>
                <span class="nav-link-text">Plugins</span>
              </a>
            </li>
          </ul>-->
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      <TimeoutModal v-model="timeout.showTimeoutModal"
                    :confirmCallback="onStayLoggedIn"
                    :timeoutClock="timeout.countdownTime"/>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import axios from 'axios'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import {FadeTransition} from 'vue2-transitions';
import {mapState} from 'vuex'
import TimeoutModal from '@/components/CORE/Modals/TimeoutModal'

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    TimeoutModal
  },
  computed: {
    ...mapState({
      config: state => state.config,
      timeout: state => state.timeout
    }),
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
    logout() {
      let closure = this
      axios.get('loguserout').then(function () {
        closure.$store.commit('RESET_SESSION')
      })
    },
    initInactivityTimeout: function () {
      window.addEventListener('load', this.resetModalTimer, true);
      const events = ['mousedown', 'mousemove', 'keypress', 'scroll'];
      const closure = this
      events.forEach(function (name) {
        document.addEventListener(name, closure.resetModalTimer, true);
      });
      this.resetModalTimer();
    },
    resetModalTimer: function (startModalTimer=true) {
      /*console.log('reset modal timer')*/
      clearTimeout(this.timeout.modalTimeout);
      if (startModalTimer)
        this.timeout.modalTimeout = setTimeout(this.onModalTimeout, 1500000)
    },
    startLogoutTimer: function () {
      if (this.$store.state.user.id === undefined)
        return false

      /*console.log('start logout timer')*/

      const logoutTimerDuration = 300000
      this.timeout.logoutTimeout = setTimeout(this.onLogoutTimeout, logoutTimerDuration)
      this.timeout.logoutDatetime = new Date(new Date().getTime() + logoutTimerDuration + 2);
      let closure = this;
      this.timeout.logoutInterval = setInterval(function () {
        closure.countdownUpdate();
      }, 1000);
    },
    countdownUpdate: function () {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = Math.floor(this.timeout.logoutDatetime / 1000) - (Math.floor(now / 1000));

      if (distance <= 0)
        this.timeout.countdownTime = '0:00'

      else {
        // Time calculations for minutes and seconds
        let minutes = Math.floor((distance % 3600) / 60)
        let seconds = '0' + Math.floor(distance % 60)

        // Display the result
        this.timeout.countdownTime = minutes + ":" + seconds.slice(-2);
      }
    },
    clearAllTimers(showTimeoutModal = false) {
      /*console.log('clear all timers')*/
      clearInterval(this.timeout.logoutInterval)
      clearTimeout(this.timeout.logoutTimeout)
      this.resetModalTimer(false)
      this.timeout.showTimeoutModal = showTimeoutModal
      this.timeout.logoutDatetime = null
    },
    onModalTimeout() {
      /*console.log('modal timeout')*/
      this.clearAllTimers(true)
      this.startLogoutTimer()
    },
    onLogoutTimeout() {
      /*console.log('logout timeout')*/
      this.clearAllTimers();
      this.logout();
    },
    onStayLoggedIn() {
      /*console.log('stay logged in')*/
      this.clearAllTimers()
    }
  },
  mounted() {
    this.initScrollbar()
    this.initInactivityTimeout()
  },
  watch: {
    '$route.path': function() {
      /*console.log('path change')*/
      this.clearAllTimers()
    },
    '$store.state.user': function() {
      /*console.log('user change')*/
      this.clearAllTimers()
    }
  }
};
</script>
<style lang="scss">
</style>
