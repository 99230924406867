<template>
  <modal :show.sync="showModal" :modal-classes="(fromArchive ? 'modal-success' : 'modal-warning') + ' modal-dialog-centered'">
    <h6 slot="header" class="modal-title" id="modal-title-notification">Archive Item</h6>

    <div class="py-3 text-center">
      <i class="fa fa-bell fa-3x"></i>
      <h4 class="heading mt-4">Are you sure you want to {{ fromArchive ? 'restore' : 'move' }}<br>"{{entity.title}}" {{ fromArchive ? 'from' : 'to' }} archive?</h4>
      <p v-if="fromArchive">This will make this item live again.</p>
      <p v-else>It will no longer be available to visitors.<br>You can restore it at any time.</p>
    </div>
    <div class="text-center" v-if="formMessage">{{formMessage}}</div>
    <template slot="footer">
      <base-button type="link"
                   class="ml-auto text-white"
                   v-on:click="showModal = false">
        No, cancel.
      </base-button>
      <base-button type="white" v-on:click="handleConfirmClick" :disabled="processing">{{ fromArchive ? 'Restore from' : 'Move to' }} archive!</base-button>
    </template>
  </modal>
</template>
<script>
  export default {
    name: 'ArchiveItemModal',
    props: {
      entity: Object,
      confirmCallback: Function,
      successRoute: String,
      value: Boolean,
      fromArchive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        processing: false,
        formMessage: false,
        showModal: false
      }
    },
    methods: {
      handleConfirmClick() {
        let closure = this
        this.processing = true
        this.confirmCallback(this.entity.id).then(function () {
          closure.processing = false
          closure.showModal = false
          if(closure.successRoute && closure.successRoute.length > 0)
            closure.$router.push(closure.successRoute)
        }).catch(function (data) {
          closure.formMessage = 'Error archiving item: ' + data
          closure.processing = false
        })
      }
    },
    watch: {
      'showModal': function (value) {
        this.$emit('input', value)
      },
      'value': function (value) {
        this.showModal = this.value
      }
    }
  }
</script>
