<template>
  <main class="page-editor">
    <form v-on:submit.prevent="formSubmit" :action="formAction" id="listForm" enctype="multipart/form-data">
      <base-header class="pb-6 bg-default">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.params.id === 'new' ? 'Add' : 'Edit' }}
              {{ $route.name }}</h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <route-breadcrumb/>
            </nav>
          </div>
          <div class="col-lg-6 col-5 text-right">
            <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
          </div>
        </div>
        <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
          <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{ errorMessage }}</div>
          <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
        </div>
      </base-header>
      <div class="container-fluid mt--6 loading-container" v-if="!showForm">
        <div class="row">
          <div class="col-12">
            Loading...
          </div>
        </div>
      </div>
      <div class="container-fluid mt--6" v-else>
        <div class="row">
          <div class="col-lg-6">
            <div class="card-wrapper">
              <!-- Form controls -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header">
                  <h3 class="mb-0">Details</h3>
                </div>
                <!-- Card body -->
                <div class="card-body">
                  <base-input label="Title" placeholder="List title" v-model="title"/>
                  <base-input label="Alias" placeholder="Alias (e.g. list-title)" v-model="alias"/>
                </div>
              </div>
            </div>
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Description</h3>
                </div>
                <div class="card-body">
                  <html-editor v-model="content"></html-editor>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Image</h3>
                </div>
                <div class="card-body">
                  <img class="list-image mb-4" v-if="$store.state.formData.listForm.image"
                       :src="$store.state.formData.listForm.image"/>
                  <file-input name="image"></file-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <list-table :items="list_assignments" :moveItem="moveItem" :removeItem="removeItem"></list-table>
          </div>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import {mapState} from 'vuex'
import {mapFields, mapMultiRowFields} from 'vuex-map-fields'
import ListTable from '@/views/CORE/ListTable'
import FileInput from '@/components/Inputs/FileInput'
import HtmlEditor from '@/components/Inputs/HtmlEditor'

export default {
  name: 'list',
  components: {
    ListTable,
    FileInput,
    HtmlEditor
  },
  data() {
    return {
      submitEnabled: true,
      errorMessage: false,
      successMessage: false,
      showForm: false,
      parent: false,
      fileModalId: false,
      fileModalIndices: [],
      refreshKey: 0,
      importURL: '',
    }
  },
  computed: {
    ...mapState({
      lists: state => state.lists
    }),
    ...mapFields([
      'formData.listForm.title',
      'formData.listForm.alias',
      'formData.listForm.image',
      'formData.listForm.content',
      'formData.listForm.list_assignments'
    ]),
    formAction() {
      return 'list/'
        + (this.$store.state.formData.listForm.id !== '' ?
          this.$store.state.formData.listForm.id + '/update' :
          (this.$route.params.parent || 'lists') + '/create')
    }
  },
  methods: {
    formSubmit(event) {
      this.submitEnabled = false
      let closure = this
      this.$store.dispatch('POST_TO_API', event).then(function (message) {
        closure.submitEnabled = true
        if (event.target.attributes.action.value.split('/')[2] === 'update') {
          closure.showFormMessage(true)
          closure.initializeForm()
        } else {
          closure.$store.commit('RESET_FORM_STATE', 'listForm')
          closure.$router.push('/list/' + message.id)
        }
      }).catch(function (message) {
        closure.showFormMessage(message)
        closure.submitEnabled = true
      })
    },
    showFormMessage(message) {
      if (message === true) {
        this.errorMessage = false
        this.successMessage = true
        let closure = this
        setTimeout(function () {
          closure.successMessage = false
        }, 2000)
      } else {
        this.errorMessage = message
      }
    },
    initializeForm() {
      if (this.$route.params.id === 'new') {
        this.$store.commit('RESET_FORM_STATE', 'listForm')
        this.showForm = true
      } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
        let closure = this
        this.$store.dispatch('LOAD_API', {
          url: 'list/' + this.$route.params.id,
          name: 'formData',
          id: 'listForm',
          form: true
        }).then(function (data) {
          closure.parent = data.parent
          closure.showForm = true
        });

      } else {
        this.showFormMessage('List id not supplied. Please go back to events and try again.')
      }
    },
    moveItem(id, change) {
      const index = this.list_assignments.findIndex(assignment => { return assignment.id === id })
      const arrayMove = require('array-move');
      const destination = index + change
      if(destination < 0 || destination > (this.list_assignments.length - 1))
        return false
      this.list_assignments = arrayMove(this.list_assignments, index, destination)
      return true
    },
    removeItem(id) {
      const index = this.list_assignments.findIndex(assignment => { return assignment.id === id })
      this.list_assignments.splice(index,1)
    }
  },
  created() {
    this.initializeForm()
  },
  watch: {
    '$route.params.id': function (id) {
      this.initializeForm(id)
    }
  }
}
</script>
<style lang="scss" scoped>
.list-image {
  display: block;
  max-width: 75%;
  margin: auto;
}

.alert-success {
  transition: .3s;
}

.loading-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
