<template>
  <div class="form-group">
    <label class="form-control-label" :for="randomId">{{ label }}</label>
    <tags-input :id="randomId"
                v-model="tagValue"
                :existing-tags="vendorList"
                :placeholder="placeholder"
                :typeahead="true"
                :typeahead-hide-discard="true"></tags-input>
  </div>
</template>

<script>
export default {
  name: "Tags",
  props: ['value', 'options', 'label', 'placeholder'],
  data() {
    return {
      tagValue: this.handleInput(this.value),
    }
  },
  computed: {
    vendorList() {
      return this.handleInput(this.options)
    },
    randomId() {
      return 'tags-' + Math.random().toString(36).replace(/[^A-Za-z1-9]+/g, '').substr(0, 5)
    }
  },
  methods: {
    handleInput(array) {

      let result = []
      if(array && Array.isArray(array))
        array.forEach(element => {
          result.push({
            key: element.id,
            value: element.title
          })
        })
      return result
    },
    handleOutput(array) {
      let result = []
      if(array)
        array.forEach(element => {
          result.push({
            id: element.key,
            title: element.value
          })
        })
      return result
    }
  },
  watch: {
    tagValue: function (value) {
      this.$emit('input', this.handleOutput(value))
    }
  }
}
</script>
<style lang="scss">
/* The input */
.tags-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tags-input input {
  flex: 1;
  background: transparent;
  border: none;
}

.tags-input input:focus {
  outline: none;
}

.tags-input input[type="text"] {
  color: #495057;
}

.tags-input-wrapper-default {
  padding: .5em .25em;

  background: #fff;

  border: 1px solid transparent;
  border-radius: .25em;
  border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
  border: 1px solid #8bbafe;
  box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
  outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
  margin-right: 0.3em;
}

.tags-input-remove {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  right: 0.3em;
  top: 0.3em;
  padding: 0.5em;
  overflow: hidden;
}

.tags-input-remove:focus {
  outline: none;
}

.tags-input-remove:before, .tags-input-remove:after {
  content: '';
  position: absolute;
  width: 75%;
  left: 0.15em;
  background: #5dc282;

  height: 2px;
  margin-top: -1px;
}

.tags-input-remove:before {
  transform: rotate(45deg);
}

.tags-input-remove:after {
  transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
  position: relative;
  display: inline-block;
  padding: 0.75em 1em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tags-input-badge-pill {
  padding-right: 1.25em;
  padding-left: 0.6em;
  border-radius: 5px;
}

.tags-input-badge-pill.disabled {
  padding-right: 0.6em;
}

.tags-input-badge-selected-default {
  color: #212529;
  background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
  color: #999 !important;
  font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges {
  padding: 0.75rem;
  background: #f1f1f1;
  line-height: 1;

  span {
    cursor: pointer;
    margin-right: 0.3em;
  }
}

/* Typeahead - dropdown */
.typeahead-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.typeahead-dropdown li {
  padding: .25em 1em;
  cursor: pointer;
}

/* Typeahead elements style/theme */
.tags-input-typeahead-item-default {
  color: #fff;
  background-color: #343a40;
}

.tags-input-typeahead-item-highlighted-default {
  color: #fff;
  background-color: #172B53 !important;
}

</style>
