<template>
  <div class="section-content">
    <div class="row">
      <div v-for="(product, index) in contents" :key="index" class="col gallery-item">
        <div class="add-item-button" v-if="contents.length < 5" v-on:click="addObject(index)">
          <i class="fa fa-plus"></i>
        </div>
        <div :class="['section-content__inner', {singleImage: contents.length < 2}]">
          <img v-if="product.image" :src="product.image"/>
          <EntitySelector v-else v-on:success="updateContents($event, index)" :expandOnFocus="contents.length > 3"/>
          <base-button v-if="contents.length > 1"  size="sm" type="danger" class="section-content__remove"
                       v-on:click="removeObject(index)"><i class="fa fa-trash"></i>
          </base-button>
          <a class="section-content__warning alert-danger"
             v-on:click="toggleFileModal(product.id, [parentIndex, index])" v-if="product.url && !product.content">
            <i class="fa fa-exclamation-triangle"></i>Missing description!
          </a>
        </div>
        <h2 v-if="product.title" v-html="product.title"></h2>
        <h3 v-if="product.vendors" v-html="product.vendors[0].title"></h3>
        <p v-if="product.content" v-html="product.content"></p>
        <div class="add-item-button right-button" v-if="contents.length < 5" v-on:click="addObject(index+1)">
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import EntitySelector from '@/components/CORE/EntitySelector'

export default {
  name: 'ProductGallery',
  components: {EntitySelector},
  props: {
    value: Array,
    toggleFileModal: Function,
    parentIndex: Number
  },
  data() {
    return {
      contents: this.value
    }
  },
  methods: {
    updateContents(event, index) {
      this.contents[index] = event
      this.$emit('input', this.contents)
      this.$forceUpdate()
    },
    addObject(index) {
      let tempId = 'new-' + Math.random().toString(36).replace(/[^A-Za-z1-9]+/g, '').substr(0, 5)
      this.contents.splice(index, 0, {
        id: tempId
      })
      this.$emit('input', this.contents)
    },
    removeObject(index) {
      this.contents.splice(index, 1)
      this.$emit('input', this.contents)
    }
  }
}
</script>
<style lang="scss" scoped>
.section-content {
  position: relative;

  &__inner {
    background: #E6E6E6;
    padding-bottom: 100%;
    position: relative;

    &.singleImage {
      padding-bottom: 50%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &:hover {
      .section-content__remove {
        visibility: visible;
      }
    }
  }

  &__remove {
    position: absolute;
    top: 5px;
    right: 5px;
    visibility: hidden;
    z-index: 101;
  }

  &__warning {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 0.85rem;
    color: #FFFFFF;
    cursor: pointer;

    i {
      margin-right: 5px;
    }

    &:hover {
      color: #FFFFFF;
    }
  }

  .col:nth-of-type(n+4) ::v-deep .entity-selector {
    right: 0;
    left: unset;
  }

  .gallery-item {
    position: relative;
    .add-item-button {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1.5rem;
      background: #E6E6E6;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      &.right-button {
        left: unset;
        right: 0;
      }
      &:hover {
        background-color: #525f7f;
        color: #FFFFFF;
      }
    }

    &:hover {
      .add-item-button {
        visibility: visible;
      }
    }
  }
}
</style>
