<template>
  <div class="section-content new-section">
    <div class="row">
      <div class="col">
        <div class="new-section__button" v-on:click="handleNewContentSection('text', index)">
          <i class="fa fa-paragraph mr-1"></i>Paragraph
        </div>
      </div>
      <div class="col">
        <div class="new-section__button" v-on:click="handleNewContentSection('images', index)">
          <i class="fa fa-images mr-1"></i>Image Gallery
        </div>
      </div>
      <div class="col">
        <div class="new-section__button" v-on:click="handleNewContentSection('products', index)">
          <i class="fa fa-shopping-cart mr-1"></i>Product Gallery
        </div>
      </div>
      <div class="col">
        <div class="new-section__button" v-on:click="handleNewContentSection('video', index)">
          <i class="fa fa-video mr-1"></i>Video Embed
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewSectionSelector',
  props: {
    index: Number,
    handleNewContentSection: Function,
    handleRemoveContentSection: Function
  }
}
</script>
<style lang="scss" scoped>
.new-section {
  padding: calc(1rem + 10px) 1rem 1rem;
  .col {
    min-height: 200px;
  }
  &__button {
    width: 100%;
    height: 100%;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #F1F1F1;
    }
  }
}
</style>
