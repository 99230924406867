export default {
    siteDetails: {
        title: 'Bayview Village Shops',
        domain: 'https://www.bayviewvillageshops.com'
    },
    storeSettings: {
        attributeTypes: ['item_price'],
        taxes: [
            {name: 'HST', percent: 13}
        ]
    },
    navMenu: {
        logo: {
            src: '',
            alt: '',
            href: ''
        },
        items: [
            {
                href: '',
                value: '',
                title: ''
            },
            {
                href: '',
                value: '',
                title: '',
                icon: '',
                class: '',
                userState: 'loggedout',
            },
            {
                onclick: 'logout',
                value: 'Sign Out',
                title: 'Sign Out',
                userState: 'loggedin',
                icon: ''
            }
        ]
    },
    categoryMenu: {
        items: [
            {
                href: '',
                value: '',
                title: ''
            },
            {
                href: '',
                value: '',
                title: ''
            }
        ]
    },
    footerMenus: [
        {
            title: '',
            class: '',
            items: [
                {
                    href: '',
                    value: '',
                    title: ''
                },
                {
                    href: '',
                    value: '',
                    title: ''
                }
            ]

        },
        {
            title: '',
            items: [
                {
                    href: '',
                    value: '',
                    title: ''
                }
            ]
        }
    ],
    footerHours: [
        {
            days: 'Monday',
            hours: '10:00am - 6:00pm'
        },
        {
            days: 'Tuesday',
            hours: '10:00am - 6:00pm'
        },
        {
            days: 'Wednesday',
            hours: '10:00am - 6:00pm'
        },
        {
            days: 'Thursday',
            hours: '10:00am - 6:00pm'
        },
        {
            days: 'Friday',
            hours: '10:00am - 6:00pm'
        },
        {
            days: 'Saturday',
            hours: '10:00am - 6:00pm'
        },
        {
            days: 'Sunday',
            hours: '10:00am - 5:00pm'
        }
    ]
}
