// TODO: Organize this into individual modules?
export default () => {
  return {
    config: {
      config_domain: 'default'
    },
    timeout: {
      modalTimeout: null,
      logoutTimeout: null,
      logoutInterval: null,
      showTimeoutModal: false,
      logoutDatetime: null,
      countdownTime: ''
    },
    products: [],
    retailerProducts: [],
    archiveProducts: [],
    vendors: [],
    ecommerceVendors: [],
    profiles : {},
    users: [],
    messages: [],
    events: [],
    collections: {},
    files: [],
    pages: {},
    formData: {
      productForm: {
        id: '',
        status: 1,
        type: '',
        title: '',
        alias: '',
        taxonomies: [],
        vendors: [],
        image: '',
        alt_image: [],
        item_tax: 0,
        item_tax2: 0,
        item_price: 0,
        content: '',
        item_ots: 0,
        item_decrement: 1,
        item_ignore_ots: false,
        prep_time: '',
        item_size: '',
        item_flavour: '',
        sequence: '',
        subsidiaries: [],
        assigned_to: []
      },
      collectionItemForm: {
        id: '',
        type: '',
        title: '',
        website: '',
        image: '',
        content: '',
        vendors: [],
        publishers: '',
        publish: '',
        attachment: []
      },
      mediaItemForm: {
        id: '',
        type: '',
        title: '',
        alias: '',
        content: '',
        mime: '',
        file_size: '',
        filename: '',
        url: ''
      },
      profileForm: {
        id: '',
        type: '',
        title: '',
        job_title: '',
        taxonomies: [],
        website: '',
        image: '',
        alt_image: '',
        content: ''
      },
      eventForm: {
        id: '',
        type: '',
        title: '',
        alias: '',
        vendors: [],
        image: '',
        content: '',
        start: '',
        end: '',
        publish: '',
        website: '',
        publishers: [],
        vimeo_id: '',
        youtube_id: '',
        attachment: []
      },
      vendorForm: {
        id: '',
        type: '',
        title: '',
        alias: '',
        content: '',
        taxonomies: [],
        addl_email: '',
        image: '',
        alt_image: [],
        assigned_to: [],
        website: '',
        phone: '',
        unit: '',
        store_hours: '',
        ecommerce_vendor: false,
        taxonomy: []
      },
      userForm: {
        id: '',
        type: '',
        firstname: '',
        lastname: '',
        alias: '',
        email: '',
        password: '',
        image: ''
      },
      pageForm: {
        id: '',
        type: '',
        title: '',
        alt_title: '',
        alias: '',
        taxonomies: [],
        publish: '',
        content: '',
        alt_image: [],
        meta_description: '',
        og_image: '',
        og_description: '',
        assigned_to: [],
        authors: [],
        access_phrase: '',
        pseudonyms: '',
        contents: [
          {
            type: 'publication',
            id: 'new-t1',
            taxonomy: {
              id: 'page-content'
            },
            title: '',
            content: '',
            contents: []
          }
        ]
      },
      messageForm: {
        id: '',
        alias: '',
        title: '',
        content: ''
      },
      listForm: {
        id: '',
        alias: '',
        title: '',
        image: '',
        content: '',
        list_assignments: [],
        type: 'publication'
      },
      tempForm: {}
    },
    product_taxonomies: [],
    profile_taxonomies: [],
    vendor_taxonomies: [],
    publication_taxonomies: [],
    product_assignees: [],
    article_assignees: [],
    article_authors: [],
    user: {},
    token: null,
    heartbeatTimer: false
  }
}
