<template>
  <modal :show="value" modal-classes="modal-warning modal-dialog-centered timeout-modal">
    <h6 slot="header" class="modal-title" id="modal-title-notification">You have been inactive for some time.</h6>
    <div class="py-3 text-center">
      <i class="fa fa-bell fa-3x"></i>
      <h4 class="heading mt-4">You will be logged out in {{ timeoutClock }}.</h4>
      <p>Please click below to stay logged in.</p>
    </div>
    <template slot="footer">
      <base-button type="white" v-on:click="handleConfirmClick">Stay logged in!</base-button>
    </template>
  </modal>
</template>
<script>
  export default {
    name: 'TimeoutModal',
    props: {
      value: Boolean,
      confirmCallback: Function,
      timeoutClock: String
    },
    methods: {
      handleConfirmClick() {
        this.confirmCallback()
      }
    }
  }
</script>
