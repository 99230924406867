/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

// Utilities
import './utils'

// Global Components
import '@/utils/components.js'

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);

// Axios
import config from "./config";
import axios from "axios";

axios.defaults.baseURL = config.serverURI;
Vue.use(axios);

// Vuex
import store from './store'

//Youtube
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

// Get config from DB
store.dispatch('LOAD_CONFIG')

// Check local storage for user to handle refreshes
if (localStorage) {
  let localUser = {}

  const localUserString = localStorage.getItem('user')
  if (localUserString) {
    localUser = JSON.parse(localUserString)
  }
  let localToken = localStorage.getItem('token')

  if (localUser && store.state.user !== localUser) {
    store.commit('SET_USER', localUser)
    store.commit('SET_TOKEN', localToken)
  }
}

// Verify Session
store.dispatch('VERIFY_SESSION')

// Before each route ensure user is logged in
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && store.state.token === null)
    next({path: '/login'})
  else
    next()
})


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store: store,
});
