<template>
  <form v-on:submit.prevent="formSubmit" :action="formAction" id="collectionItemForm" enctype="multipart/form-data">
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.params.id === 'new' ? 'Add' : 'Edit'}}
            {{$route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <div class="alert-danger"
               v-if="$store.state.formData.mediaItemForm.mime.indexOf('image') === 0 && content.length === 0">
            Missing image description! This is required for accessibility.
          </div>
          <base-button v-else size="sm" type="neutral" v-on:click="$router.push('/collection/' + $route.params.parent)">
            Back
          </base-button>
          <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
        </div>
      </div>
      <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
        <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{errorMessage}}</div>
        <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
      </div>
    </base-header>
    <div class="container-fluid mt--6 loading-container" v-if="!showForm">
      <div class="row">
        <div class="col-12">
          Loading...
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" v-else>
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Details</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-input label="Title" placeholder="Collection Item title" v-model="title"/>
                <base-input label="Date/Time" type="date" v-model="publish"
                            v-if="$route.params.parent === 'job-postings' || $route.params.parent === 'media-room'"/>
                <base-input label="Link" placeholder="https://www.example.com" v-model="website"/>
                <Tags label="Retailer" class="mb-0" v-model="vendors" :options="allVendors" placeholder="Add a vendor"
                      v-if="$route.params.parent === 'job-postings'"/>
                <Tags label="Publisher" class="mb-0" v-model="publishers" placeholder="Add a publisher"
                      v-if="$route.params.parent === 'media-room'"/>
              </div>
            </div>
          </div>
          <div class="card-wrapper" v-if="$route.params.parent === 'media-room'">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Attachments</h3>
              </div>
              <div class="card-body">
                <file-input name="attachment"></file-input>
                <div class="attachments" v-if="attachment.length > 0">
                  <div class="attachment" v-for="file in attachment"
                       :key="file.id">
                    <i class="far fa-file"></i>
                    <a :href="file.url" target="_blank">{{file.filename}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card-wrapper" v-if="$route.params.parent !== 'job-postings'">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Image</h3>
              </div>
              <div class="card-body">
                <img class="collection-image mb-4" v-if="$store.state.formData.collectionItemForm.image"
                     :src="$store.state.formData.collectionItemForm.image"/>
                <file-input name="image"></file-input>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Description</h3>
              </div>
              <div class="card-body">
                <html-editor v-model="content"></html-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <base-button size="sm" type="danger" class="section-content__remove"
                   v-on:click="showDeleteModal = true">Delete Item
      </base-button>
    </div>
    <DeleteItemModal v-model="showDeleteModal"
                     :entity="$store.state.formData.collectionItemForm"
                     :confirmCallback="deleteItem"
                     :successRoute="'/collection/' + $route.params.parent"/>
  </form>
</template>

<script>
  import {mapFields} from 'vuex-map-fields'
  import {mapState} from "vuex";
  import FileInput from '@/components/Inputs/FileInput'
  import HtmlEditor from '@/components/Inputs/HtmlEditor'
  import DeleteItemModal from '@/components/CORE/Modals/DeleteItemModal'
  import Tags from '@/components/CORE/Tags'

  export default {
    name: 'collection-item',
    components: {
      FileInput,
      HtmlEditor,
      DeleteItemModal,
      Tags
    },
    data() {
      return {
        submitEnabled: true,
        errorMessage: false,
        successMessage: false,
        showDeleteModal: false,
        showForm: false,
        parent: false
      }
    },
    computed: {
      ...mapFields([
        'formData.collectionItemForm.title',
        'formData.collectionItemForm.publish',
        'formData.collectionItemForm.website',
        'formData.collectionItemForm.vendors',
        'formData.collectionItemForm.publishers',
        'formData.collectionItemForm.content',
        'formData.collectionItemForm.attachment'
      ]),
      ...mapState({
        allVendors: state => state.vendors,
      }),
      formAction() {
        return 'collection-item/' +
          (this.$store.state.formData.collectionItemForm.id !== '' ?
            this.$store.state.formData.collectionItemForm.id + '/update' : this.$route.params.parent + '/create')
      }
    },
    methods: {
      formSubmit(event) {
        this.submitEnabled = false
        let closure = this
        this.$store.dispatch('POST_TO_API', event).then(function (message) {
          closure.submitEnabled = true
          closure.$store.dispatch('LOAD_API',
            {
              url: 'collection/' + closure.$route.params.parent,
              name: 'collections',
              id: 'closure.$route.params.id'
            }
          );
          if (event.target.attributes.action.value.split('/')[2] === 'update') {
            closure.showFormMessage(true)
            closure.initializeForm()
          } else {
            closure.$store.commit('RESET_FORM_STATE', 'collectionItemForm')
            closure.$store.commit('SET_STATE', {name: 'collections', id: closure.$route.params.parent, data: []})
            closure.$router.push('/collection/collection-item/' + message.id  + '/' + closure.$route.params.parent)
          }
        }).catch(function (message) {
          closure.showFormMessage(message)
          closure.submitEnabled = true
        })
      },
      showFormMessage(message) {
        if (message === true) {
          this.errorMessage = false
          this.successMessage = true
          let closure = this
          setTimeout(function () {
            closure.successMessage = false
          }, 2000)
        } else {
          this.errorMessage = message
        }
      },
      initializeForm() {
        if (this.$route.params.id === 'new') {
          this.$store.commit('RESET_FORM_STATE', 'collectionItemForm')
          this.showForm = true
        } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
          let closure = this
          this.$store.dispatch('LOAD_API', {
            url: 'collection-item/' + this.$route.params.id,
            name: 'formData',
            id: 'collectionItemForm',
            form: true
          }).then(function (data) {
            if (data.publish)
              closure.$store.commit('SET_FORM_STATE',
                {name: 'collectionItemForm', field: 'publish', data: data.publish.split('T')[0]}
              )
            closure.parent = data.parent
            closure.showForm = true
          });

        } else {
          this.showFormMessage('Collection item not supplied. Please go back to collection and try again.')
        }

        if (this.allVendors.length === 0) {
          this.$store.dispatch('LOAD_API', 'vendors');
        }
      },
      deleteItem(id) {
        this.$store.commit('SET_STATE', {name: 'collections', id: this.$route.params.parent, data: []})
        return this.$store.dispatch('DELETE_ENTITY', {type: 'article', id: id})
      },
    },
    created() {
      this.initializeForm()
    },
    watch: {
      '$route.params.id': function (id) {
        this.initializeForm(id)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .collection-image {
    display: block;
    max-width: 50%;
    margin: auto;
  }

  .alert-success {
    transition: .3s;
  }

  .loading-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .attachment {
    margin: 10px 0;
    i {
      margin-right: 10px;
    }
  }
</style>
