var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.items}},[_c('el-table-column',{attrs:{"label":"Project","min-width":"310px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('a',{staticClass:"avatar mr-3",attrs:{"href":'#/shop/product/' + row.id}},[_c('img',{attrs:{"alt":row.image.alt,"src":row.image.src}})]),_c('div',{staticClass:"media-body"},[_c('a',{attrs:{"href":'#/shop/product/' + row.id}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.title))])])])])]}}])}),_c('el-table-column',{attrs:{"label":"Vendor","min-width":"200px","prop":"vendor","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":'#/shop/vendor/' + row.vendor.id}},[_c('span',{staticClass:"status"},[_vm._v(_vm._s(row.vendor.name))])])]}}])}),_c('el-table-column',{attrs:{"label":"Price","prop":"item_price","min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item_price))+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":'#/shop/product/'+row.id}},[_vm._v("Edit Product")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":'#/shop/vendor/'+row.vendor.id}},[_vm._v("View Vendor")])])],1)]}}])})],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-end"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("All Products")])])}]

export { render, staticRenderFns }