<template>
  <main class="page-editor">
    <form v-on:submit.prevent="formSubmit" :action="formAction" id="pageForm" enctype="multipart/form-data">
      <div v-if="autosaveTimer.showNotice" class="autosaving-status alert-success">Autosaving...</div>
      <base-header class="pb-6 bg-default">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.params.id === 'new' ? 'Add' : 'Edit' }}
              {{ $route.name }}</h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <route-breadcrumb/>
            </nav>
          </div>
          <div class="col-lg-6 col-5 text-right">
            <base-button size="sm" type="neutral" v-if="$route.params.id !== 'new'"
                         v-on:click="$router.push('/' + publicationType + '/new/' + pageGroup)">New
            </base-button>
            <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save
            </base-button>
          </div>
        </div>
        <div v-if="autosave !== false" class="row">
          <div class="autosave-notice col-12 mb-3">
            <h6>There is an autosaved version of this record from {{ autosave.date | datetime }}.</h6>
            <button v-on:click.prevent="restoreAutosave">Restore</button>
            <button v-on:click.prevent="removeAutosave">Delete</button>
          </div>
        </div>
        <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
          <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{ errorMessage }}</div>
          <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
        </div>
      </base-header>
      <div class="container-fluid mt--6 loading-container" v-if="!showForm">
        <div class="row">
          <div class="col-12">
            Loading...
          </div>
        </div>
      </div>
      <div class="container-fluid mt--6" v-else>
        <div class="row">
          <div class="col-lg-6">
            <div class="card-wrapper">
              <!-- Form controls -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header">
                  <h3 class="mb-0">Details</h3>
                </div>
                <!-- Card body -->
                <div class="card-body">
                  <base-input label="Title" placeholder="Page title" v-model="title"/>
                  <!--                  <base-input label="Alias" placeholder="Alias (e.g. page-title)" v-model="alias"/>-->
                  <base-input label="Alias" placeholder="Alias (e.g. product-title)" v-model="alias"
                              v-on:keyup="handleAliasKeyup" v-on:blur="handleAliasBlur"
                              :class="['alias', {'in-use': !aliasAvailable.alias}]" id="alias"/>
                  <base-input label="Pseudonym" placeholder="Pesudonym (e.g. page-title-alternate)" v-model="pseudonyms"
                              v-on:keyup="handleAliasKeyup" v-on:blur="handleAliasBlur"
                              :class="['pseudonyms', {'in-use': !aliasAvailable.pseudonyms}]" id="pseudonyms"/>
                  <!--                  <Tags label="Pseudonyms" placeholder="Pesudonyms (e.g. page-title-alternate)" v-model="pseudonyms"/>-->
                  <base-input label="Date/Time" type="date" v-model="publish" v-if="publicationType === 'article'"/>
                  <Tags label="Categories" v-model="taxonomies" :options="allTaxonomies" placeholder="Add a category"
                        v-if="publicationType === 'article'"/>
                  <Tags label="Lists" v-model="assigned_to" :options="allAssignees" placeholder="Add article to a list"
                        v-if="publicationType === 'article'"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Image</h3>
                </div>
                <div class="card-body">
                  <img class="page-image mb-4" v-if="$store.state.formData.pageForm.image"
                       :src="$store.state.formData.pageForm.image"/>
                  <file-input name="image"></file-input>
                </div>
              </div>
            </div>
            <div class="card-wrapper" v-if="publicationType === 'article'">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Author</h3>
                </div>
                <div class="card-body">
                  <Tags label="Author(s)" v-model="authors" :options="allAuthors"
                        placeholder="Add an Author"/>
                  <router-link to="/profiles/blog-authors"><small>Manage Authors</small></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Content</h3>
                </div>
                <ArticleContent :contents="contents" :toggleFileModal="toggleFileModal"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6" v-if="publicationType === 'article'">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Lead & Thumbnail</h3>
                </div>
                <div class="card-body">
                  <base-input label="Alternative Title" placeholder="" v-model="alt_title"/>
                  <base-input label="Lead">
                    <textarea class="form-control" rows="4" v-model="content"></textarea>
                    <small>Lead text for excerpt, subheadline, etc.</small>
                  </base-input>
                  <label>
                    Thumbnail Image
                    <img class="page-image my-4"
                         v-if="image || (alt_image && alt_image.length > 0)"
                         :src="(alt_image && alt_image.length > 0) ? alt_image[0] : image"/>
                    <span v-if="image && (!alt_image || alt_image.length === 0)" class="d-block mb-3">
                      <small class="text-success">Using main image (no separate image provided)</small>
                    </span>
                    <file-input name="alt_image"></file-input>
                    <small>Optional</small>
                  </label>
                </div>
              </div>
            </div>
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Password Protected</h3>
                </div>
                <div class="card-body">
                  <base-input label="Pass Phrase" placeholder="Pass phrase required to access content"
                              v-model="access_phrase"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card-wrapper">
              <div class="card">
                <div class="card-header">
                  <h3 class="mb-0">Search Engine & Social Optimization</h3>
                </div>
                <div class="card-body">
                  <base-input label="Meta Description">
                    <textarea class="form-control" rows="4" v-model="meta_description"></textarea>
                    <small>Search engine preview text</small>
                  </base-input>
                  <base-input label="Open Graph Description">
                    <textarea class="form-control" rows="4" v-model="og_description"></textarea>
                    <small>Social sharing description</small>
                  </base-input>
                  <label>
                    Open Graph Image
                    <img class="page-image my-4" v-if="og_image || image" :src="og_image || image"/>
                    <span v-if="image && !og_image" class="d-block mb-3"><small class="text-success">Using main image (no separate image provided)</small></span>
                    <file-input name="og_image"></file-input>
                    <small>Social sharing image</small>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="container" v-if="['ianmorrison','superadmin'].indexOf($store.state.user.alias) > -1">
      <form v-on:submit.prevent="importFormSubmit" class="container">
        Import alias:
        <input type="text" name="import_url" label="Import URL" v-model="importURL" class="form-control d-inline-block"
               style="max-width: 400px"/>
        <input type="submit" value="Submit" class="btn btn-default"/>
      </form>
    </div>
    <FileDetailModal :fileId="fileModalId" :toggleFileModal="toggleFileModal"
                     v-on:success="updateFile($event, fileModalIndices)"/>
  </main>
</template>

<script>
import {mapState} from 'vuex'
import {mapFields, mapMultiRowFields} from 'vuex-map-fields'
import getDefaultState from '@/store/state'
import ArticleContent from '@/components/CORE/ArticleContent'
import FileInput from '@/components/Inputs/FileInput'
import FileDetailModal from '@/components/CORE/Modals/FileDetailModal'
import Tags from '@/components/CORE/Tags'
import axios from 'axios'

export default {
  name: 'page',
  components: {
    FileInput,
    FileDetailModal,
    Tags,
    ArticleContent
  },
  data() {
    return {
      submitEnabled: true,
      errorMessage: false,
      successMessage: false,
      showForm: false,
      parent: false,
      fileModalId: false,
      fileModalIndices: [],
      refreshKey: 0,
      importURL: '',
      pageGroup: 'publications',
      publicationType: 'article',
      aliasSet: true,
      aliasAvailable: {
        alias: true,
        pseudonyms: true
      },
      aliasTimeout: {
        alias: {},
        pseudonyms: {}
      },
      initialAlias: {
        alias: '',
        pseudonyms: ''
      },
      autosaveTimer: {
        sinceLast: '',
        notice: '',
        showNotice: false
      },
      totalAPICalls: 4,
      apiCallsRun: 0,
      startAutosaving: false,
      autosave: false
    }
  },
  computed: {
    ...mapState({
      allTaxonomies: state => state.publication_taxonomies,
      allAuthors: state => state.article_authors,
      image: state => state.formData.pageForm.image,
      alt_image: state => state.formData.pageForm.alt_image,
      og_image: state => state.formData.pageForm.og_image,
      allAssignees: state => state.article_assignees
    }),
    ...mapFields([
      'formData.pageForm.title',
      'formData.pageForm.alias',
      'formData.pageForm.content',
      'formData.pageForm.meta_description',
      'formData.pageForm.og_description',
      'formData.pageForm.alt_title',
      'formData.pageForm.publish',
      'formData.pageForm.taxonomies',
      'formData.pageForm.authors',
      'formData.pageForm.assigned_to',
      'formData.pageForm.access_phrase',
      'formData.pageForm.pseudonyms'
    ]),
    ...mapMultiRowFields(['formData.pageForm.contents']),
    formAction() {
      return this.publicationType
        + '/'
        + (this.$store.state.formData.pageForm.id !== '' ?
          this.$store.state.formData.pageForm.id + '/update' :
          (this.$route.params.parent || (this.publicationType === 'article' ? 'publications' : 'pages'))
          + '/create')
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.initializeForm(id)
    },
    'title': function (data) {
      if (!this.aliasSet) {
        this.alias = this.slugify(data)
      }
    },
    'alias': function (value) {
      this.handleAliasChange('alias', value)
    },
    'pseudonyms': function (value) {
      this.handleAliasChange('pseudonyms', value)
    }
  },
  methods: {
    formSubmit(event) {
      this.submitEnabled = false
      let closure = this
      this.$store.dispatch('POST_TO_API', event).then(function (message) {
        closure.submitEnabled = true
        closure.$store.dispatch('LOAD_API', 'pages');
        if (event.target.attributes.action.value.split('/')[2] === 'update') {
          closure.showFormMessage(true)
          closure.initializeForm()
        } else {
          closure.$store.commit('RESET_FORM_STATE', 'pageForm')
          closure.$router.push('/' + closure.publicationType + '/' + message.id)
        }
      }).catch(function (message) {
        closure.showFormMessage(message)
        closure.submitEnabled = true
      })
    },
    importFormSubmit() {
      this.importFromAPI(this.importURL)
    },
    showFormMessage(message) {
      if (message === true) {
        this.errorMessage = false
        this.successMessage = true
        let closure = this
        setTimeout(function () {
          closure.successMessage = false
        }, 2000)
      } else {
        this.errorMessage = message
      }
    },
    initializeForm() {
      const closure = this
      this.apiCallsRun = 0

      if (this.allTaxonomies.length === 0) {
        this.$store.dispatch('LOAD_API', {
          name: 'publication_taxonomies', url: 'taxonomies/publication'
        }).then(function () {
          closure.incrementAPICounter()
        })
      }
      if (this.allAuthors.length === 0) {
        this.$store.dispatch('LOAD_API', {
          name: 'article_authors', url: 'profiles/blog-authors'
        }).then(function () {
          closure.incrementAPICounter()
        })
      }
      if (this.allAssignees.length === 0 && this.publicationType === 'article') {
        this.$store.dispatch('LOAD_API', {
          name: 'article_assignees', url: 'group/article-lists'
        }).then(function () {
          closure.incrementAPICounter()
        })
      }
      if (this.$route.params.id === 'new') {
        this.$store.commit('RESET_FORM_STATE', 'pageForm')
        this.showForm = true
      } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
        this.$store.dispatch('LOAD_API', {
          url: 'page/' + this.$route.params.id + '?image_object=true',
          name: 'formData',
          id: 'pageForm',
          form: true
        }).then(function (data) {
          closure.incrementAPICounter()
          closure.parent = data.parent

          if (data.publish)
            closure.$store.commit('SET_FORM_STATE',
              {name: 'pageForm', field: 'publish', data: data.publish.split('T')[0]}
            )

          if (data.contents === undefined)
            closure.$store.commit('SET_FORM_STATE',
              {name: 'pageForm', field: 'contents', data: getDefaultState().formData.pageForm.contents}
            )

          closure.showForm = true
          closure.initialAlias.alias = data.alias
          closure.handleAliasChange('alias', data.alias)
          closure.initialAlias.pseudonyms = data.pseudonyms
          closure.handleAliasChange('pseudonyms', data.pseudonyms)
        })

      } else {
        this.showFormMessage('Event id not supplied. Please go back to events and try again.')
      }
    },
    handleAliasKeyup(e) {
      if (['Tab', 'Shift'].indexOf(e.key) === -1)
        this.aliasSet = e.target.value.length > 0
    },
    handleAliasBlur(e) {
      this[e.target.id] = this.slugify(e.target.value)
    },
    handleAliasChange(field, value) {
      clearTimeout(this.aliasTimeout[field])
      let closure = this
      if (value === this.initialAlias[field]) {
        this.aliasAvailable[field] = true
      } else if (this.initialAlias[field] !== false) {
        this.aliasTimeout[field] = setTimeout(function () {
          closure.checkAliasAvailability(field, value)
        }, 1000)
      }
    },
    checkAliasAvailability(field, value) {
      let closure = this
      if (value.length > 0)
        this.$store.dispatch('ALIAS_AVAILABLE', value).then(result => {
          closure.aliasAvailable[field] = result.data
        })
      return true
    },
    incrementAPICounter() {
      const closure = this
      this.apiCallsRun = this.apiCallsRun + 1
      if (this.apiCallsRun === this.totalAPICalls)
        setTimeout(function () {
          closure.startAutosaving = true
        }, 5000)
    },
    importFromAPI(alias) {
      let url = 'https://www.bayviewvillageshops.com/api/' + alias + '.json'
      let closure = this
      axios.get(url).then(response => {
        let data = response.data

        // Regular Fields
        closure.$store.commit('SET_FORM_STATE', {name: 'pageForm', field: 'id', data: ''})
        closure.$store.commit('SET_FORM_STATE', {name: 'pageForm', field: 'title', data: data.title})
        closure.$store.commit('SET_FORM_STATE', {name: 'pageForm', field: 'alias', data: data.alias})
        closure.$store.commit('SET_FORM_STATE', {name: 'pageForm', field: 'content', data: data.description})

        // Date
        let dateObject = new Date(data.publish_date * 1000)
        let formattedDate = dateObject.getFullYear() + '-' + ('0' + (dateObject.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObject.getDate()).slice(-2)
        closure.$store.commit('SET_FORM_STATE', {name: 'pageForm', field: 'publish', data: formattedDate})

        // Conditional Values
        closure.$store.commit('SET_FORM_STATE', {
          name: 'pageForm', field: 'meta_description', data: data.meta_description || data.description
        })
        closure.$store.commit('SET_FORM_STATE', {
          name: 'pageForm', field: 'og_description', data: data.og_description || data.description
        })

        //Optional Values
        if (data.alt_title)
          closure.$store.commit('SET_FORM_STATE', {
            name: 'pageForm', field: 'alt_title', data: data.alt_title
          })

        // Images
        if (data.headerImage) {
          closure.$store.commit('SET_FORM_STATE', {
            name: 'pageForm',
            field: 'image',
            data: 'https://www.bayviewvillageshops.com' + data.headerImage.replace('.13', '')
          })
        }
        if (data.meta_image && data.meta_image.replace('.15', '') !== data.headerImage.replace('.13', ''))
          closure.$store.commit('SET_FORM_STATE', {
            name: 'pageForm',
            field: 'alt_image',
            data: ['https://www.bayviewvillageshops.com' + data.meta_image.replace('.15', '')]
          })
        if (data.og_image && data.og_image.replace('.16', '') !== data.headerImage.replace('.13', ''))
          closure.$store.commit('SET_FORM_STATE', {
            name: 'pageForm', field: 'og_image', data: 'https://www.bayviewvillageshops.com' + data.og_image
          })

        // Contents
        let contents = JSON.parse(JSON.stringify(data.contents))
        for (let i = 0; i < contents.length; i++) {
          let section = contents[i]

          section.id = 'new-s' + i
          section.taxonomy = {
            id: 'page-content'
          }

          if (section.type === 'largeImage') {
            section.taxonomy = {
              id: 'page-image-list'
            }
            section.contents = [
              {
                id: 'new-l' + i + '0',
                type: 'file',
                url: 'https://www.bayviewvillageshops.com' + section.content.replace('.13', '')
              }
            ]
            delete section.content
          } else if (section.type === 'images' && section.contents) {
            section.taxonomy = {
              id: 'page-image-list'
            }
            for (let ii = 0; ii < section.contents.length; ii++) {
              let image = section.contents[ii]
              image.id = 'new-i' + i + ii
              image.type = 'file'
              image.url = 'https://www.bayviewvillageshops.com' + image.content.replace('.13', '')
              delete image.content
            }

            closure.refreshKey = closure.refreshKey + 1
          } else if (section.type === 'products' && section.contents) {

            section.taxonomy = {
              id: 'page-item-list'
            }
            for (let ii = 0; ii < section.contents.length; ii++) {
              let product = section.contents[ii]
              product.id = 'new-p' + i + ii
              product.type = 'item'
              product.image = 'https://www.bayviewvillageshops.com' + product.image
              if (product.store && product.store.alias)
                product.vendors = [{
                  id: product.store.alias,
                  title: product.store.title
                }]
              delete product.store
              delete product.alias
            }
          }

          closure.refreshKey = closure.refreshKey + 1

          if (section.contents) {
            section.list_assignments = section.contents
            delete section.contents
          }

          section.type = 'publication'

        }
        closure.$store.commit('SET_FORM_STATE', {
          name: 'pageForm',
          field: 'contents',
          data: contents
        })
        closure.showForm = true
      });
    },
    toggleFileModal(fileId, contentIndices) {
      this.fileModalId = fileId
      this.fileModalIndices = contentIndices
    },
    updateFile(success, indices) {
      this.$store.state.formData.pageForm.contents[indices[0]].contents[indices[1]] = success
      this.refreshKey = this.refreshKey + 1
    },
    handleDrop(drop) {
      console.log(drop.list)
    },
    getAutosave() {
      const closure = this
      this.$store.dispatch('GET_AUTOSAVE', {
        id: this.$route.params.id,
        form: 'pageForm'
      }).then(function (data) {
        closure.autosave = data
      })
    },
    setAutosave() {
      let closure = this
      this.$store.dispatch('SET_AUTOSAVE', this.$store.state.formData.pageForm).then(function (message) {
        if (message.type === 'success') {
          closure.autosaveTimer.showNotice = true;
          clearTimeout(closure.autosaveTimer.notice)
          closure.autosaveTimer.notice = setTimeout(function () {
            closure.autosaveTimer.showNotice = false
          }, 1500)
        }
      })
    },
    restoreAutosave() {
      this.$store.commit('SET_STATE', {name: 'formData', id: 'pageForm', data: this.autosave.content, merge: true});
      this.autosave = false
    },
    removeAutosave() {
      const closure = this
      this.$store.dispatch('REMOVE_AUTOSAVE', {id: this.$route.params.id}).then(function (message) {
        if (message.type === 'success')
          closure.autosave = false
      });
    }
  },
  created() {
    this.publicationType = this.$route.path.split('/')[1]
    this.pageGroup = this.$route.params.parent || (this.publicationType === 'article' ? 'publications' : 'pages')
    this.initializeForm()
    const closure = this
    this.getAutosave(false);
    this.$store.subscribe((mutation, state) => {
      if (closure.startAutosaving === true
        && mutation.type === 'updateField' && mutation.payload.path !== 'pageForm'
        && mutation.payload.path.indexOf('formData.pageForm') === 0) {
        clearTimeout(closure.autosaveTimer.sinceLast)
        closure.autosaveTimer.sinceLast = setTimeout(function () {
          closure.setAutosave()
        }, 5000)
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.card {
  overflow-x: hidden;
}

.page-image, .author-image {
  display: block;
  max-width: 75%;
  margin: auto;
}

.alert-success {
  transition: .3s;
}

.loading-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alias.in-use,
.pseudonyms.in-use {
  ::v-deep input {
    background-color: #FFDDDD;
  }

  ::v-deep label::after {
    content: 'in use';
    color: #f5365c;
    font-weight: bold;
  }
}

.alias.cant-match,
.pseudonyms.cant-match {
  ::v-deep input {
    background-color: #FFDDDD;
  }

  ::v-deep label::after {
    content: 'in use';
    color: #f5365c;
    font-weight: bold;
  }
}

.autosaving-status {
  position: fixed;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 12px;
  padding: 5px;
  z-index: 1000;
}

.autosave-notice {
  h6 {
    display: inline-block;
    font-size: 13px;
    color: #FFFFFF;
  }
  button {
    background: transparent;
    margin-left: 15px;
    border: 1px rgba(255,255,255,0.6) solid;
    padding: 2px 5px;
    color: #FFFFFF;
    font-size: 13px;
  }
}

</style>
