<template>
  <form v-on:submit.prevent="formSubmit" :action="formAction" id="mediaItemForm" enctype="multipart/form-data">
    <base-header class="pb-6 bg-default">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.params.id === 'new' ? 'Add' : 'Edit' }}
            {{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button v-if="$route.params.id !== 'new'" size="sm" type="neutral"
                       v-on:click="$router.go(-1)">Back
          </base-button>
          <base-button v-else size="sm" type="neutral" v-on:click="$router.push('/media/' + $route.params.parent)">
            Back
          </base-button>
          <base-button native-type="submit" size="sm" type="neutral" :disabled="!submitEnabled">Save</base-button>
        </div>
      </div>
      <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
        <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{ errorMessage }}</div>
        <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
      </div>
    </base-header>
    <div class="container-fluid mt--6" v-if="showForm">
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Details</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-input label="Title" placeholder="Media Item title" v-model="title"/>
                <base-input label="Alias" placeholder="Media Item alias" v-model="alias"/>
                <ul class="file-details" v-if="$store.state.formData.mediaItemForm.mime">
                  <li><strong>Type:</strong> {{ $store.state.formData.mediaItemForm.mime }}</li>
                  <li><strong>Original Name:</strong> {{ $store.state.formData.mediaItemForm.filename }}</li>
                  <li><strong>Size:</strong> {{ $store.state.formData.mediaItemForm.file_size }} bytes</li>
                  <li><strong>Public URL:</strong> {{ publicURL }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card-wrapper"
               v-if="$store.state.formData.mediaItemForm.mime && $store.state.formData.mediaItemForm.mime.indexOf('image') === 0">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Preview</h3>
              </div>
              <div class="card-body">
                <img class="media-image mb-4" :src="$store.state.formData.mediaItemForm.url"/>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">File</h3>
              </div>
              <div class="card-body">
                <file-input name="upload_file"></file-input>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Description</h3>
              </div>
              <div class="card-body">
                <div class="alert-warning"
                     v-if="$store.state.formData.mediaItemForm.mime.indexOf('image') === 0 && content.length === 0">
                  <i class="fa fa-warning"></i>Missing image description! This is required for accessibility.
                </div>
                <textarea class="form-control" v-model="content"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-else/>
  </form>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import FileInput from '@/components/Inputs/FileInput'

export default {
  name: 'media-item',
  components: {
    FileInput
  },
  data() {
    return {
      submitEnabled: true,
      errorMessage: false,
      successMessage: false,
      showForm: false,
      parent: false
    }
  },
  computed: {
    ...mapFields([
      'formData.mediaItemForm.title',
      'formData.mediaItemForm.alias',
      'formData.mediaItemForm.content'
    ]),
    formAction() {
      return 'media/' +
        (this.$store.state.formData.mediaItemForm.id !== '' ?
          this.$store.state.formData.mediaItemForm.id + '/update' : this.$route.params.parent + '/create')
    },
    fileId: function () {
      return this.$route.params.id
    },
  },
  methods: {
    formSubmit(event) {
      this.submitEnabled = false
      let closure = this
      this.$store.dispatch('POST_TO_API', event).then(function (message) {
        closure.submitEnabled = true
        closure.$store.dispatch('LOAD_API', 'files/' + closure.$route.params.parent);
        if (event.target.attributes.action.value.split('/')[2] === 'update') {
          closure.showFormMessage(true)
          closure.initializeForm()
        } else {
          closure.$store.commit('RESET_FORM_STATE', 'mediaItemForm')
          closure.$router.push('/media/file/' + message.id)
        }
      }).catch(function (message) {
        closure.showFormMessage(message)
        closure.submitEnabled = true
      })
    },
    showFormMessage(message) {
      if (message === true) {
        this.errorMessage = false
        this.successMessage = true
        let closure = this
        setTimeout(function () {
          closure.successMessage = false
        }, 2000)
      } else {
        this.errorMessage = message
      }
    },
    initializeForm() {
      if (this.$route.params.id === 'new') {
        this.$store.commit('RESET_FORM_STATE', 'mediaItemForm')
        this.showForm = true
      } else if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
        let closure = this
        this.$store.dispatch('LOAD_API', {
          url: 'media/' + this.$route.params.id,
          name: 'formData',
          id: 'mediaItemForm',
          form: true
        }).then(function (data) {
          closure.parent = data.parent
          closure.showForm = true
          closure.publicURL = 'https://api.bayviewvillageshops.com/file/' + data.alias + '.' + data.url.split('.').splice(-1)
        });

      } else {
        this.showFormMessage('Media item not supplied. Please go back to media listing and try again.')
      }
    }
  },
  created() {
    this.initializeForm()
  },
  watch: {
    '$route.params.id': function (id) {
      this.initializeForm(id)
    }
  }
}
</script>
<style lang="scss" scoped>
.media-image {
  display: block;
  max-width: 50%;
  margin: auto;
}

.alert-success {
  transition: .3s;
}

.loading-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-details {
  list-style: none;
  padding: 0;
  font-size: 0.9rem;

  strong {
    font-weight: 600;
  }
}
</style>
