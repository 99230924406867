<template>
  <modal :show.sync="this.fileId ? true : false">
    <form v-on:submit.prevent="formSubmit" :action="formAction" id="mediaItemForm" ref="modalForm"
          enctype="multipart/form-data">
      <h2>Edit File Description</h2>
      <div class="row mb-3 px-3" v-if="errorMessage || successMessage">
        <div class="col-12 py-2 px-3 alert-danger" v-show="errorMessage">{{errorMessage}}</div>
        <div class="col-12 py-2 px-3 alert-success" v-show="successMessage">Changes submitted successfully.</div>
      </div>
      <div class="container-fluid m-5 loading-container" v-if="!showForm">
        <div class="row">
          <div class="col-12">
            Loading...
          </div>
        </div>
      </div>
      <div v-else>
        <img class="media-image mb-4" :src="$store.state.formData.mediaItemForm.url"/>
        <label>Description:</label>
        <textarea class="form-control mb-4" v-model="content"></textarea>
      </div>
      <div class="modal-footer">
        <base-button type="link" class="ml-auto" v-on:click="closeModal()">Close</base-button>
        <base-button native-type="submit" type="primary" :disabled="!submitEnabled">Save changes</base-button>
      </div>
    </form>
  </modal>
</template>

<script>
  import {mapFields} from 'vuex-map-fields'

  export default {
    name: 'FileDetailModal',
    props: ['fileId', 'toggleFileModal'],
    data() {
      return {
        submitEnabled: true,
        errorMessage: false,
        successMessage: false,
        showForm: false,
        parent: false
      }
    },
    computed: {
      ...mapFields([
        'formData.mediaItemForm.title',
        'formData.mediaItemForm.alias',
        'formData.mediaItemForm.content'
      ]),
      formAction() {
        return 'media/' + this.$store.state.formData.mediaItemForm.id + '/update'
      }
    },
    methods: {
      formSubmit(event) {
        this.submitEnabled = false
        let closure = this
        this.$store.dispatch('POST_TO_API', event).then(function (message) {
          closure.submitEnabled = true
          closure.showFormMessage(true)
          closure.initializeForm(true)
        }).catch(function (message) {
          closure.showFormMessage(message)
          closure.submitEnabled = true
        })
      },
      showFormMessage(message) {
        if (message === true) {
          this.errorMessage = false
          this.successMessage = true
          let closure = this
          setTimeout(function () {
            closure.successMessage = false
          }, 2000)
        } else {
          this.errorMessage = message
        }
      },
      initializeForm(emit) {
        if (!this.fileId) {
          return false
        }
        let closure = this
        this.$store.dispatch('LOAD_API', {
          url: 'media/' + this.fileId,
          name: 'formData',
          id: 'mediaItemForm',
          form: true
        }).then(function (data) {
          closure.showForm = true
          if (emit) {
            closure.$emit('success', data)
          }
        });
      },
      closeModal() {
        this.$store.commit('RESET_FORM_STATE', 'mediaItemForm')
        this.toggleFileModal(false, [])
      }
    },
    created() {
      this.initializeForm()
    },
    updated() {
      if (this.fileId && this.fileId !== this.$store.state.formData.mediaItemForm.id)
        this.initializeForm()
    }
  }
</script>
<style lang="scss" scoped>
  .file-detail-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      max-width: 500px;
      width: 100%;
    }
  }

  .media-image {
    display: block;
    max-width: 100%;
    margin: auto;
  }

  .alert-success {
    transition: .3s;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-details {
    list-style: none;
    padding: 0;
    font-size: 0.9rem;

    strong {
      font-weight: 600;
    }
  }
</style>
