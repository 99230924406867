<template>
  <div class="alt_images my-1">
    <div v-for="(image, index) in images" :key="index" class="alt_images__item">
      <img v-if="image.url" :src="image.url" class="alt_images__image"/>
      <ImageDrop v-on:success="updateContents($event, index)" :showInstructions="image.url === undefined"/>
      <!--          <a class="section-content__warning alert-danger" v-on:click="toggleFileModal(image.id, [parentIndex, index])"
                   v-if="image.url && !image.content">
                  <i class="fa fa-exclamation-triangle"></i>Missing description!
                </a>-->
      <base-button size="sm" type="danger" class="alt_images__remove"
                   v-on:click="removeObject(index)"><i class="fa fa-trash"></i>
      </base-button>
    </div>
    <div class="add-item-button right-button mx-3" v-on:click="addObject()">
      <i class="fa fa-plus"></i>
    </div>
  </div>
</template>
<script>

import ImageDrop from '@/components/CORE/ImageDrop'

export default {
  name: 'ImagesGallery',
  components: {ImageDrop},
  props: {
    images: Array
  },
  data() {
    return {
      contents: this.value
    }
  },
  methods: {
    updateContents(event, index) {
      this.images[index] = event
      this.$emit('input', this.images)
      this.$forceUpdate()
    },
    addObject() {
      let tempId = 'new-' + Math.random().toString(36).replace(/[^A-Za-z1-9]+/g, '').substr(0, 5)
      this.images.push({
        id: tempId
      })
      this.$emit('input', this.images)
    },
    removeObject(index) {
      this.images.splice(index, 1)
      this.$emit('input', this.images)
    }
  }
}
</script>
<style lang="scss" scoped>
.alt_images {

  &__remove {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
  }

  &__item {
    position: relative;
    cursor: pointer;
    display: inline-block;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 1px solid #DDDDDD;
    margin: 0 15px 15px 0;
    overflow: hidden;

    &:hover {
      .alt_images__remove {
        display: block;
      }
    }

    ::v-deep .drop {
      h3 {
        font-size: 15px;
        margin: 0 0 10px;
      }
      p {
        font-size: 12px;
        line-height: 1.2em;
        display: none;
      }
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .add-item-button {
    cursor: pointer;
  }
}
</style>
