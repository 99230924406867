var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.items}},[_c('el-table-column',{attrs:{"label":this.publicationType === 'article' ? 'Article' : 'Page',"min-width":"310px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('a',{attrs:{"href":'#/' + _vm.publicationType + '/' + row.id}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.title))])])])])]}}])}),(_vm.publicationType === 'article')?_c('el-table-column',{attrs:{"label":"Categories","min-width":"250px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._l((row.taxonomies),function(taxonomy,index){return [_vm._v(" "+_vm._s(taxonomy.title)),((index + 1) < row.taxonomies.length)?[_vm._v(", ")]:_vm._e()]})]}}],null,false,1153089248)}):_vm._e(),(_vm.publicationType === 'article')?_c('el-table-column',{attrs:{"label":"Publish Date","min-width":"250px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.publish_date)+" ")]}}],null,false,1652543853)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":'#/' + _vm.publicationType + '/'+row.id}},[_vm._v("Edit Page")]),(_vm.config.config_domain == 'forvestglobalcom')?_c('a',{staticClass:"dropdown-item",attrs:{"target":"_blank","href":'https://www.forvest.ch/resource/'+row.alias}},[_vm._v("View Live")]):_vm._e()])],1)]}}])})],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-end"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("All Pages")])])}]

export { render, staticRenderFns }