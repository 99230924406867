<template>
  <div class="content-selector">
    <div class="justify-content-start">
      <i class="fa fa-arrow-up mr-1" v-on:click="shiftSection(index, -1)"></i>
      <i class="fa fa-arrow-down mr-1" v-on:click="shiftSection(index, 1)"></i>
      <i class="fa fa-trash mr-1" v-on:click="handleRemoveContentSection(index)"></i>
    </div>
    <div class="justify-content-center">
      <div v-if="type==='page-content'"><i class="fa fa-paragraph mr-1"></i>Paragraph</div>
      <div v-if="type==='page-image-list'"><i class="fa fa-images mr-1"></i>Gallery</div>
      <div v-if="type==='page-item-list'"><i class="fa fa-shopping-bag mr-1"></i>Products</div>
      <div v-if="type==='page-video-embed'"><i class="fa fa-video mr-1"></i>Video</div>
      <div v-if="type==='selector'"><i class="fa fa-layer-group mr-1"></i>New Section</div>
    </div>
    <div class="justify-content-end" v-on:mouseleave="showAddButtons=false">
      <div class="add-menu">
        <button v-if="showAddButtons && !content.title" class="add-button" v-on:click.prevent="toggleTitle(index, true)">
          Title
        </button>
        <button v-if="showAddButtons" class="add-button"
                v-on:click.prevent="handleNewContentSection('selector', index+1)">Section Below
        </button>
        <button v-if="showAddButtons" class="add-button"
                v-on:click.prevent="handleNewContentSection('selector', index)">Section Above
        </button>
      </div>
      <i class="fa fa-plus" v-on:click="showAddButtons = !showAddButtons"></i>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ContentSectionMenu',
  data() {
    return {
      showAddButtons: false
    }
  },
  props: {
    index: Number,
    handleNewContentSection: Function,
    handleRemoveContentSection: Function,
    shiftSection: Function,
    toggleTitle: Function,
    content: Object
  },
  computed: {
    type: function () {
      return this.content.taxonomy === undefined ? 'selector' : this.content.taxonomy.id
    }
  }
}
</script>
<style lang="scss" scoped>
.content-selector {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 30px;
  font-size: 15px;
  display: flex;
  line-height: 15px;
  padding: 5px 10px;
  background-color: rgba(230, 230, 230, 1);

  i {
    margin-left: 5px;
  }

  > div {
    display: flex;
    width: 50%;
    align-items: center;
  }
}

.add-button {
  padding: 2px 10px;

  &:hover {
    background: #525f7f;
    color: #FFFFFF;
  }

  border: 1px solid #525f7f;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 11px;
  margin-left: 5px;
}
</style>
